import { Component, OnInit, Input } from '@angular/core';
import { CommonDataService } from '../../services/common-data.service';
import { ContactService } from '../../services/contact.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { LoadingStatus } from '../../common/loading-status';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { CommonErrorStateMatcher } from '../../common/error-status-matcher';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styles: []
})

export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  matcher = new CommonErrorStateMatcher();

  constructor(
    private fb: FormBuilder,
    public loadingStatus: LoadingStatus,
    public contactService: ContactService,
    public store: CommonDataService,
    public snackBar: MatSnackBar) {
      this.createForm();
    }

  ngOnInit() {
  }

  createForm() {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: '',
      enquiry: ['', Validators.required]
    });
  }


  contact(): void {
    if(this.contactForm.invalid) return;

    var opt = this.contactForm.value;
    this.contactService.contact(opt)
      .subscribe(contactResult => {
        console.log(contactResult);

        let config = new MatSnackBarConfig();
        //config.verticalPosition = 'top';
        config.duration = 3000;
        this.snackBar.open('Successfully submitted. We will be in touch soon. Thank you.', 'Close', config);

        this.contactForm.reset({
          name: '',
          email: '',
          phone: '',
          enquiry: ''
        })
      });
  }
}
