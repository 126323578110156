export class ParallaxScroll{
  settings: any;
    constructor (opt) {
        this.settings = Object.assign({}, {
          sizeSetCalculators: [],
          scrollSpeed: 0.4,
          padding: 50
        }, opt)
      }

      init () {
        var self = this
        self.initParallaxImages()
        $(window).scroll(function () {
          self.onScroll()
        })
        $(window).resize(function () {
          self.initParallaxImages()
        })
      }

      initParallaxImages () {
        var settings = this.settings
        var winW = $(window).width()
        var winH = $(window).height()
        var self = this
        $('.parallax').each(function (inx, px) {
          var $px = $(px)
          // var effect = $px.data('effect')
          var pxHSetting = parseFloat($px.data('pxh'));
          var pxH = pxHSetting || (winW > 1024 ? 250 : 200)
          //console.log(pxHSetting, pxH)
          var scrollH = (winH + pxH) * settings.scrollSpeed * (1 - pxH / winH) // max scroll height variation
          var pannelH = pxH

          var imgW = parseFloat($px.data('width'))
          var imgH = parseFloat($px.data('height'))

          var marginLeft = 0
          var w = (scrollH + pannelH + settings.padding * 2) * imgW / imgH
          if (w <= winW) {
            w = winW
          } else {
            marginLeft = -(w - winW) / 2
          }
          var h = w * imgH / imgW
          // console.log(w, h, pannelH, pxH)
          $px.css('background-size', w + 'px ' + h + 'px')
          $px.css('height', pannelH)
          $px.css('height', pannelH)
          // $px.css('background-image', 'url("' + src + '")')
          $px.attr('data-px-height', pxH)
          $px.attr('data-img-height', h)
          $px.attr('data-img-leftmargin', marginLeft)

        })
        window.setTimeout(function () {
          self.onScroll()
        }, 100)
      }

      onScroll () {
        var self = this
        var settings = this.settings
        var scrollTop = $(window).scrollTop()
        var winH = $(window).height()
        $('.parallax').each(function (inx, px) {
          var $px = $(px)
          var pxH = parseFloat($px.attr('data-px-height'))

          var offsetTop = $px.offset().top
          var percent = ((winH + pxH) - (offsetTop + pxH - scrollTop)) / (winH + pxH)
          var imgH = parseFloat($px.attr('data-img-height'))

          if (scrollTop + winH > offsetTop && scrollTop < offsetTop + pxH) {
            //console.log("1");
            
            if($px.data('type') == "banner"){
              self.moveBg($px, (pxH - imgH) / 2 + (0.9 - percent) * (winH + pxH) * settings.scrollSpeed)
            }
            else{
              self.moveBg($px, (pxH - imgH) / 2 + (0.5 - percent) * (winH + pxH) * settings.scrollSpeed)
            } 
            //self.moveBg($px, (pxH - imgH) / 2 + (0.5 - percent) * (winH + pxH) * settings.scrollSpeed)
          }
          else if (scrollTop + winH < offsetTop) {
            //console.log("2");
            self.moveBg($px, (pxH - imgH) / 2 + (0.5 - 0) * (winH + pxH) * settings.scrollSpeed)
          }
          else if (scrollTop > offsetTop + pxH) {
            //console.log("3");
            self.moveBg($px, (pxH - imgH) / 2 + (0.5 - 1) * (winH + pxH) * settings.scrollSpeed)
          }
        })
      }

      moveBg ($pxlayer, imageTop) {
        $pxlayer.css('background-position',
          Math.round($pxlayer.attr('data-img-leftmargin')) + 'px ' + Math.round(imageTop) + 'px')
      }
}
