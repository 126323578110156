import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-app-version',
  templateUrl: './app-version.component.html',
  styles: []
})
export class AppVersionComponent implements OnInit {

  ver: string;
  updateDate: string;
  constructor() { }

  ngOnInit() {
    this.ver = environment.ver;
    this.updateDate = environment.updateDate;
  }

  update() {
    window.location.reload();
  }
}
