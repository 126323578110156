import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private scripts: any = {};
  private styles: any = {};
  constructor() { }
  loadJs(...scripts: string[]) {
    var promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadCss(...styles: string[]) {
    var promises: any[] = [];
    styles.forEach((style) => promises.push(this.loadStyle(style)));
    return Promise.all(promises);
  }

  loadScript(url: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.scripts[url] && this.scripts[url].loaded) {
        resolve({ script: url, loaded: true, status: 'Already Loaded' });
      }
      else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.onload = () => {
          this.scripts[url] = { loaded: true };
          resolve({ script: url, loaded: true, status: 'Loaded' });
        };
        script.onerror = (error: any) => resolve({ script: url, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  loadStyle(url: string) {
    return new Promise((resolve, reject) => {
      //resolve if already loaded
      if (this.styles[url] && this.styles[url].loaded) {
        resolve({ style: url, loaded: true, status: 'Already Loaded' });
      }
      else {
        //load style
        let style = document.createElement('link');
        style.type = 'text/css';
        style.rel = 'stylesheet';
        style.href = url;
        style.onload = () => {
          this.styles[url] = { loaded: true };
          resolve({ style: url, loaded: true, status: 'Loaded' });
        };
        style.onerror = (error: any) => resolve({ style: url, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(style);
      }
    });
  }
}
