import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  Router,
  ActivatedRoute,
  ParamMap,
  NavigationStart,
  Event as NavigationEvent,
} from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { TopHeaderComponent } from "../top-header/top-header.component";
//import { Location } from '@angular/common';

@Component({
  selector: "app-public-layout",
  templateUrl: "./public-layout.component.html",
  styleUrls: ["./public-layout.component.scss"],
})
export class PublicLayoutComponent implements OnInit, OnDestroy {
  routeSubscriber: Subscription;
  year = 2019;
  constructor(
    //private location: Location,
    private route: ActivatedRoute,
    public router: Router
  ) {
    this.routeSubscriber = router.events.subscribe((event: NavigationEvent) => {
      const contentContainer = window;
      if ("scrollBehavior" in document.documentElement.style) {
        contentContainer.scrollTo({
          behavior: "smooth",
          left: 0,
          top: 0,
        });
      } else {
        contentContainer.scrollTo(0, 0);
      }

      TopHeaderComponent.collapse();
    });
  }

  ngOnInit() {
    this.year = new Date().getFullYear();
    //console.log(this.location);
    //console.log(this.route.snapshot);
    //console.log(this.route.snapshot['_routerState'].url)
  }
  ngOnDestroy(): void {
    this.routeSubscriber.unsubscribe();
  }
  cssClass() {
    return this.route.snapshot["_routerState"].url == "/"
      ? "subpage homepage"
      : "subpage";
  }
}
