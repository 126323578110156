import { Component, OnInit } from '@angular/core';
import { ParallaxScroll } from '../../scrolly/scrolly';

@Component({
  selector: 'app-common-banner',
  templateUrl: './app-common-banner.component.html',
  styles: []
})
export class AppCommonBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(){
    var d = new ParallaxScroll({});
    d.init();
  }

}
