import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { OrderService } from '../../services/order.service';
import * as dateUtils from '../../utils/datetime';
import * as textUtils from '../../utils/text';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styles: []
})
export class OrderDetailComponent implements OnInit {

  oToken: string;
  order: any;
  formatDateTime = dateUtils.formatDateTime;
  formatMoney = textUtils.formatMoney;

  constructor(
    private orderService: OrderService,
    public router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.oToken = params['o'];
      this.loadOrder(this.oToken);
    });
  }

  loadOrder(orderToken): void {
    if (!orderToken) return;
    this.orderService.getPaidOrder(orderToken)
      .subscribe(order => {
        console.log(order);
        this.order = order;
      },
        err => {
          alert('Fail to load order information');
          console.log(err);
        }
      );
  }

}
