import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private url = environment.apiEndpoint + '/api/Category';
  constructor(private http: HttpClient) { }
  getCategories(): Observable<any[]> {
    return this.http.get<any[]>(this.url);
  }
  getCategory(id): Observable<any> {
    return this.http.get<any>(this.url + "/" + id);
  }
}
