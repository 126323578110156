import { environment } from "../../../environments/environment";
import { Injectable, NgZone } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { auth } from "firebase/app";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { CommonUIService } from "@app/common-ui/services/common-ui.service";
import { User } from "@app/core/entities/user";
import { AUTH_URLS } from "../auth-urls";
import { AuthService } from "./auth.service";
import * as formUtils from "@jaba/j6-utils/src/form";

@Injectable({
  providedIn: "root",
})
export class AuthControlService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private commonUIService: CommonUIService,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {}

  //#region email/password
  // sign in with email/password
  async signIn(email, password, returnUrl = null) {
    try {
      const result = await this.afAuth.auth.signInWithEmailAndPassword(
        email,
        password
      );
      if (result.user) {
        const user = await this.authService.setUser(result.user, true);
        const roles = (user && user.roles) || [];

        if (roles.some((x) => x === "Admin")) {
          // admin
          this.router.navigate(["/admin"]);
        } else {
          // member
          this.router.navigate([returnUrl || this.onAuthSuccessUrl(user)]);
        }
        // this.ngZone.run(() => { this.router.navigate([this.onAuthSuccessUrl(user)]); });
      } else throw "No user found";
      return true;
    } catch (error) {
      this.displayError(error);
      return false;
    }
  }

  // sign up with email/password
  async signUp(email, password, returnUrl = null, formData) {
    try {
      const result = await this.afAuth.auth.createUserWithEmailAndPassword(
        email,
        password
      );
      const ajaxResult = await this.authService
        .updateProfile(
          Object.assign(this.authService.wrapUser(result.user, null), formData),
          true
        )
        .toPromise();
      const errors = formUtils.getErrors(ajaxResult);
      //console.log(ajaxResult, errors);
      if (errors.length > 0) throw errors.join(" ");

      const user = await this.authService.setUser(result.user, true);
      //await this.afAuth.auth.currentUser.sendEmailVerification();
      this.router.navigate([returnUrl || AUTH_URLS.verifyEmailUrl]);
      return true;
    } catch (error) {
      this.displayError(error);
      return false;
    }
  }
  //#endregion

  //#region OAuth providers
  // Sign in with Google
  async googleAuth() {
    return this.authLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  async authLogin(provider) {
    try {
      provider.setCustomParameters({
        // Forces account selection even when one account
        // is available.
        prompt: "select_account",
      });

      const result = await this.afAuth.auth.signInWithPopup(provider);
      console.log(result);
      const ajaxResult = await this.authService
        .updateProfile(this.authService.wrapUser(result.user, null), false)
        .toPromise();
      const errors = formUtils.getErrors(ajaxResult);
      //console.log(ajaxResult, errors);
      if (errors.length > 0) throw errors.join(" ");

      const user = await this.authService.setUser(result.user, true);
      this.router.navigate([this.onAuthSuccessUrl(user)]);
      //this.updateUserData(result.user);
      return true;
    } catch (error) {
      this.displayError(error);
      return false;
    }
  }
  //#endregion

  //#region sign out
  async signOut() {
    await this.afAuth.auth.signOut();
    localStorage.removeItem("user");
    this.router.navigate([AUTH_URLS.loginUrl]);
  }
  //#endregion

  //#region UI
  displayError(error, handler = null) {
    console.log(error);
    this.commonUIService.displayMessage(
      "Error",
      typeof error === "string" ? error : error.message
    );
  }
  displayMessage(title, message, handler = null) {
    this.commonUIService.displayMessage(title, message);
  }
  //#endregion

  onAuthSuccessUrl(user) {
    return AUTH_URLS.onAuthSuccessUrl;

    // if (this.authService.checkUserRole(user, 'admin')) {
    //   return '/admin/orders';
    // //} else if (this.authService.checkUserRole(user, 'station')) {
    // //  return '/station/dashboard';
    // } else
    //   return AUTH_URLS.onAuthSuccessUrl;
  }
}
