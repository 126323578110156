import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ContactService } from '../../services/contact.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styles: []
})

export class ContactsComponent implements OnInit {
  idSubscriber: Subscription;

  displayedColumns: string[] = ['contactName', 'phone', 'email', 'dateCreated', 'dateUpdated'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  contacts= []
  contactType:string;
  totalCnt=-1;

  constructor(
    private route: ActivatedRoute,
    private contactService: ContactService) { }

  ngOnInit() {
    this.idSubscriber = this.route.queryParams
      .subscribe(
        (params: { Params }) => {
          let type = params['type'];
          console.log(type);
          this.getContacts(type);
          this.dataSource.sort = this.sort;
        });

  }

  getContacts(type): void {
    this.contactService.getContacts(type)
    .subscribe(data => {
      //console.log(data);
      this.contacts = data
      this.dataSource.data = data;
      this.contactType = type;
      this.totalCnt = data? data.length : 0;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  totalCountHtml() {
    return this.totalCnt >= 0 ? this.contactType + ' (' + this.totalCnt + ')' : 'Loading...';
  }
}
