import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { FeedbackService } from '../../services/feedback.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import * as arrayUtils from '../../utils/array';

@Component({
  selector: 'app-feedback-list',
  templateUrl: './feedback-list.component.html',
  styles: []
})
export class FeedbackListComponent implements OnInit {
  yearFeedbacks= []
  totalCnt=0;

  constructor(
    private route: ActivatedRoute,
    private feedbackService: FeedbackService) { }

  ngOnInit() {
    this.getFeedbacks();
  }

  getFeedbacks(): void {
    this.feedbackService.getFeedbacks()
    .subscribe(data => {
      // console.log(data);
      this.parseFeedbacks(data);
    });
  }

  parseFeedbacks(feedbacks) {
    arrayUtils.clear(this.yearFeedbacks);
    var currentYear = 0;
    var inx = -1;
    feedbacks.forEach(f => {
      if (f.feedbackDate) {
        //console.log(f.feedbackDate)
        if (f.year != currentYear) {
          this.yearFeedbacks.push({ year: f.year, feedbacks: []});
          currentYear = f.year;
          inx++;
        }

        this.yearFeedbacks[inx].feedbacks.push(f);
      }
    });
  }

}
