import { CmsPageController } from './cms-page-controller'

export class CmsRadioSender {
  controller: CmsPageController;
  constructor(controller: CmsPageController) {
    this.controller = controller;
  }

  private sendMessageToParent(action: string, data: any) {
    var cloned = JSON.parse(JSON.stringify(data));
    window.parent.postMessage({
      action: action,
      data: cloned
    }, "*");
  }

  public saveAllDirtyHtml(htmlChanges) {
    // skip another unneccesary round trip
    this.sendMessageToParent('return-all-dirty-html', {
      returnTo: '_saveAllDirtyHtml',
      htmlChanges: htmlChanges
    });
  }

  public selectControllerItem(data) {
    this.sendMessageToParent("select-controller-item", data);
  }

  public returnAllDirtyHtml(returnTo, htmlChanges) {
    this.sendMessageToParent('return-all-dirty-html', {
      returnTo: returnTo,
      htmlChanges: htmlChanges
    });
  }

  public returnHtml(id, html) {
    this.sendMessageToParent('return-html', {
      id: id,
      html: html
    });
  }

}
