import * as ioUtils from '@jaba/j6-utils/src/io';
import { DropzoneOption } from './dropzone-option';
import { DropzoneData, DropzoneFile, DropzoneFileStatus } from './dropzone-data';
import { AuthService } from '@app/auth/services/auth.service';

import uuidv1 from 'uuid/v1';

export class Dropzone {
  settings: DropzoneOption;
  authService: AuthService;
  data: DropzoneData;
  dropArea: Element;
  constructor(data: DropzoneData, dropArea: Element, opt: DropzoneOption, authService: AuthService = null) {
    this.authService = authService;
    this.data = data;
    this.init(dropArea, opt);
  }

  init(dropArea: Element, opt: DropzoneOption) {
    this.dropArea = dropArea
    this.settings = Object.assign({}, {
      startUploadAfterSelection: true,
      maxNumberOfFiles: 1
    }, opt)
    this.initEventHandler();
  }

  initEventHandler() {
    // Prevent default drag behaviors
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.dropArea.addEventListener(eventName, (e) => { e.preventDefault(); e.stopPropagation(); }, false)
      document.body.addEventListener(eventName, (e) => { e.preventDefault(); e.stopPropagation(); }, false)
    });

    // Highlight drop area when item is dragged over it
    ['dragenter', 'dragover'].forEach(eventName => {
      this.dropArea.addEventListener(eventName, (e) => { this.dropArea.classList.add('highlight') }, false)
    });

    ['dragleave', 'drop'].forEach(eventName => {
      this.dropArea.addEventListener(eventName, (e) => { this.dropArea.classList.remove('highlight') }, false)
    });

    // Handle dropped files
    this.dropArea.addEventListener('drop', (e: any) => {
      var dt = e.dataTransfer;
      var files = dt.files

      this.filesSelected(files);
    }, false);

    // Handle click
    const fileInput = this.dropArea.querySelector('input[type="file"]');
    fileInput.addEventListener('change', (e: any) => { this.filesSelected(e.target.files); });
  }

  filesSelected(files: any[]) {
    this.clearFilterErrors();
    this.addFiles(files);
    if (this.settings.startUploadAfterSelection)
      this.startUploading();
  }

  addFiles(files: any[]) {

    for (var i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) {
        //console.log(this, this.data.files)
        const exisingInQueue = this.data.files.some(x => x.fileName == file.name && x.status != DropzoneFileStatus.Failed);
        if (!exisingInQueue) {
          const newFile = {
            fileId: uuidv1(),
            fileName: file.name,
            fileType: file.type,
            filePathInServer: null,
            size: file.size,
            sizeText: ioUtils.getSizeText(file.size),
            ext: ioUtils.getExt(file.name),
            status: DropzoneFileStatus.Ready,
            percentComplete: 0,
            fileObject: file,
            thumbnail: null,
            errors: []
            //uploadTimestamp: null
          };

          const numOfActiveFiles = this.data.files.filter(x => x.status != DropzoneFileStatus.Failed).length;
          //console.log(numOfActiveFiles, this.settings.maxNumberOfFiles)
          if (numOfActiveFiles >= this.settings.maxNumberOfFiles) {
            //newFile.status = DropzoneFileStatus.Ignored;
            this.data.filterErrors.push('Max ' + (
              this.settings.maxNumberOfFiles == 1 ? '1 file' : this.settings.maxNumberOfFiles + ' files') +
              ' allowed.');
            break;
          }
          this.filterFile(newFile);

          if (this.settings.onFilterFile) {
            this.settings.onFilterFile(newFile);
          }

          //console.log(newFile)
          if (newFile.status == DropzoneFileStatus.Ready) {
            this.data.files.push(newFile);
            this.getThumbnail(newFile);
          } else {
            newFile.errors.forEach(x => this.data.filterErrors.push(x));
          }

        }
      }
    };
    //console.log('added', this.data.files);
  }

  removeFile(fileId) {
    this.clearFilterErrors();
    const inx = this.data.files.findIndex(x => x.fileId == fileId);
    if (inx >= 0) {
      this.data.files.splice(inx, 1);
    }
  }

  getThumbnail(file) {
    if (!file.fileType.match(/image.*/)) return;

    const rotation = {
      1: 'rotate(0deg)',
      3: 'rotate(180deg)',
      6: 'rotate(90deg)',
      8: 'rotate(270deg)'
    };

    const self = this;
    let reader = new FileReader()
    reader.readAsDataURL(file.fileObject)
    //reader.readAsArrayBuffer(file.fileObject)
    reader.onloadend = function () {
      file.thumbnail = reader.result;
      // file.thumbnail = "data:" + file.fileObject.type + ";base64," + _arrayBufferToBase64(reader.result);
      // const orientation = self.checkOrientation(reader.result);
      // file.orientation = rotation[orientation];
    }

    // function _arrayBufferToBase64(buffer) {
    //   var binary = ''
    //   var bytes = new Uint8Array(buffer)
    //   var len = bytes.byteLength;
    //   for (var i = 0; i < len; i++) {
    //     binary += String.fromCharCode(bytes[i])
    //   }
    //   return window.btoa(binary);
    // }
  }

  // checkOrientation(buffer) {
  //   var value = 1; // Non-rotated is the default
  //   try {
  //     //console.log(buffer);
  //     var scanner = new DataView(buffer);
  //     //console.log(scanner);
  //     var idx = 0;
  //     if (buffer.length < 2 || scanner.getUint16(idx) != 0xFFD8) {
  //       return 1;
  //     }
  //     idx += 2;
  //     var maxBytes = scanner.byteLength;
  //     while (idx < maxBytes - 2) {
  //       var uint16 = scanner.getUint16(idx);
  //       idx += 2;
  //       switch (uint16) {
  //         case 0xFFE1: // Start of EXIF
  //           var exifLength = scanner.getUint16(idx);
  //           maxBytes = exifLength - idx;
  //           idx += 2;
  //           break;
  //         case 0x0112: // Orientation tag
  //           // Read the value, its 6 bytes further out
  //           // See page 102 at the following URL
  //           // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
  //           value = scanner.getUint16(idx + 6, false);
  //           maxBytes = 0; // Stop scanning
  //           break;
  //       }
  //     }
  //   } catch (e) {
  //     console.log(e);
  //     value = 1;
  //   }
  //   return value;
  // }

  clearFilterErrors() {
    this.data.filterErrors = [];
  }

  filterFile(file: DropzoneFile) {
    if (this.settings.accept && !file.fileType.match(new RegExp(this.settings.accept))) {
      file.errors.push('[skipped] ' + file.fileName + ' : ' + this.settings.acceptErrorMessage)
    };
    if (this.settings.maxFileSizeInBytes && file.size > this.settings.maxFileSizeInBytes) {
      file.errors.push('[skipped] ' + file.fileName + ' : ' + 'The file size is over the limit ' + ioUtils.getSizeText(this.settings.maxFileSizeInBytes));
    }

    if (file.errors && file.errors.length > 0) {
      file.status = DropzoneFileStatus.Ignored;
    }
  }

  startUploading() {
    this.data.files.forEach((file) => {
      if (file.status == DropzoneFileStatus.Ready) {
        this.uploadFile(file);
      }
    });
  }

  uploadFile(file: any) {
    var url = this.settings.uploadUrl;
    var xhr = new XMLHttpRequest()
    var formData = new FormData()
    var self = this;

    // Update progress (can be used to show progress indicator)
    xhr.upload.addEventListener("progress", (e) => {
      //console.log(e);
      if (e.lengthComputable) {
        var percentComplete = Math.round((e.loaded * 100.0 / e.total) || 100);
        file.percentComplete = percentComplete;
      }
    })

    xhr.addEventListener('readystatechange', (e) => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        file.percentComplete = 100;
        file.status = DropzoneFileStatus.Completed;
        const ret = JSON.parse(xhr.responseText);
        if (ret.errors && ret.errors.length > 0) {
          console.log('error - ', ret.errors)
          file.status = DropzoneFileStatus.Failed;
          file.percentComplete = 0;
          ret.errors.forEach(x => file.errors.push(x.errorMessage));
        }
        else {
          file.filePathInServer = ret.results.fileInfo && ret.results.fileInfo.filePath;

          if (self.settings.onFileUploaded) {
            //console.log(self.data.files)
            const completedAll = !self.data.files.some(x => x.status != DropzoneFileStatus.Completed);
            self.settings.onFileUploaded(file, completedAll, ret.results);
          }
        }
      }
      else if (xhr.readyState == 4 && xhr.status != 200) {
        console.log('error - ', xhr)
        file.status = DropzoneFileStatus.Failed;
        file.percentComplete = 0;
        if (xhr.status == 401)
          file.errors.push('Session timeout. Please try again after login.');
        else
          file.errors.push(xhr.responseText);
      }
    })

    formData.append('fileId', file.fileId)
    formData.append('files', file.fileObject)
    xhr.open('POST', url, true)
    xhr.addEventListener("error", (e) => { alert("File uploading failed"); }, false);
    xhr.addEventListener("abort", (e) => { alert("File uploading cancelled"); }, false);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

    const token = this.authService && this.authService.currentUser && this.authService.currentUser.token;
    if (token)
      xhr.setRequestHeader('Authorization', 'Bearer ' + token);

    xhr.send(formData)
  }

  getStatusText(file) {
    if (file.status == 0)
      return "ready";
    else if (file.status == 1)
      return "uploading";
    else if (file.status == 2)
      return "uploaded";
    else if (file.status == 3)
      return "failed";
    return "";
  }

  getStatusCssClass(file) {
    return this.getStatusText(file)
  }
}
