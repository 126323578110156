import { Component, OnInit, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { CommonDataService } from './services/common-data.service';
import { ButtonComponent } from './cms/partials/button/button.component';
import { CheckForUpdateService } from './services/check-for-update.service';
import { PromptUpdateService } from './services/prompt-update.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  data: any;
  constructor(
    injector: Injector,
    private promptUpdateService: PromptUpdateService,
    private checkForUpdateService: CheckForUpdateService,
    private commonDataService: CommonDataService,
    private router: Router) {
    // Convert `PopupComponent` to a custom element.
    const buttonElement = createCustomElement(ButtonComponent, { injector });
    // Register the custom element with the browser.
    customElements.define('j6-button', buttonElement);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log('send pageview', event.urlAfterRedirects);
        (<any>window).gtag('config', 'UA-127145316-1', {
          'page_path': event.urlAfterRedirects
        });
      }
    });
  }

  public ngOnInit() {
    this.commonDataService.data.subscribe(x => this.data = x)
  }
}
