import { Injectable } from '@angular/core';

@Injectable()
export class LoadingStatus {
  status: string;
  error: string;
  errorDetails: string;

  constructor() {
    this.status = 'loading';
    this.error = null;
    this.errorDetails = null;
  }

  showErrorMessage(errMsg, detailMessage, errObj) {
    this.error = 'Error - ' + errMsg;
    this.errorDetails = detailMessage;
    console.log(errObj);
  }

  loaded() {
    this.status = 'loaded';
    this.resetErrors();
  }

  loading() {
    this.status = 'loading';
    this.resetErrors();
  }

  resetErrors() {
    this.error = null
    this.errorDetails = null;
  }

  isLoaded() {
    return this.status == 'loaded';
  }
}
