import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { OrderService } from "@app/services/order.service";
@Component({
  selector: "app-orderview",
  templateUrl: "./orderview.component.html",
  styles: [],
})
export class PastOrderViewComponent implements OnInit {
  orderId: string;
  model: any;

  constructor(
    private orderService: OrderService,
    public router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.orderId = params.get("id");
      this.loadOrder(this.orderId);
    });
  }

  loadOrder(orderId): void {
    if (!orderId) return;
    this.orderService.getPastOrder(orderId).subscribe(
      (order) => {
        console.log(order);
        this.model = order;
      },
      (err) => {
        alert("Fail to load order information");
        console.log(err);
      }
    );
  }
}
