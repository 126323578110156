import { Component, Inject, OnInit, NgZone  } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { CommonErrorStateMatcher } from '../../common/error-status-matcher';

@Component({
  selector: 'app-address-editor',
  templateUrl: './address-editor.component.html',
  styles: []
})
export class AddressEditorComponent implements OnInit {
  formGroup: FormGroup;
  matcher = new CommonErrorStateMatcher();
  constructor(
    //private ngZone: NgZone,
    public dialogRef: MatDialogRef<AddressEditorComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

    onNoClick(): void {
      console.log(this.dialogRef);
      console.log('address editor close');
      this.dialogRef.close();
      // this.ngZone.run(() => {
      //   this.dialogRef.close();
      // });
    }

    ngOnInit() {
      //{ street: '', suburb:'', state:'SA', postcode: '', country: 'AU' }
      this.formGroup = this.fb.group({
        addressLine: [this.data.addressLine, Validators.required],
        suburb: [this.data.suburb, Validators.required],
        state: [this.data.state, Validators.required],
        postcode: [this.data.postcode, Validators.required]
      });
    }

    onSubmit() {
      console.log('address editor submit');
      this.dialogRef.close(this.formGroup.value);

      // this.ngZone.run(() => {
      //   this.dialogRef.close(this.formGroup.value);
      // });
    }

}
