import { Component, OnInit, Input } from '@angular/core';
import { LoadingStatus } from '../../common/loading-status';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styles: []
})
export class LoadingComponent implements OnInit {
  @Input() loadingStatus: LoadingStatus;
  @Input() pageLoading: boolean;
  constructor() {
    this.pageLoading = false;
   }

  ngOnInit() {
  }

}
