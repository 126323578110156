import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../environments/environment';
//import * as fromAuth from '@app/auth/reducers/auth.reducer';
import * as fromCms from './cms.reducer';

export interface State {
  //auth: fromAuth.State,
  cms: fromCms.State
}

export const reducers: ActionReducerMap<State> = {
  //auth: fromAuth.reducer,
  cms: fromCms.reducer
};

export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
  return (state: State, action: any): any => {
    const result = reducer(state, action);
    console.groupCollapsed(action.type);
    console.log('prev state', state);
    console.log('action', action);
    console.log('next state', result);
    console.groupEnd();

    return result;
  };
}

export const metaReducers: MetaReducer<State>[] = !environment.production ? [logger] : [];

// export const selectAuthState = createFeatureSelector<fromAuth.State>('auth');
// export const selectIsLoggedIn = createSelector(
//   selectAuthState,
//   fromAuth.selectIsLoggedIn
// );

export const selectCmsState = (state: State) => state.cms;
export const selectCmsPage = createSelector(
  selectCmsState,
  (state: any) => state.page
);
export const selectCmsMenu = createSelector(
  selectCmsState,
  (state: any) => state.menu
);
export const selectMasqueradingUser = createSelector(
  selectCmsState,
  (state: any) => state.masqueradingUser
);
