import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from "@app/auth/services/auth.service";

import { AUTH_URLS } from "@app/auth/auth-urls";
import { LoadingStatus } from '@app/common-ui/loading-status';
import * as utils from '@app/shared/utils';
import { CommonUIService } from '@app/common-ui/services/common-ui.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styles: []
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('email') emailRef: ElementRef;
  AUTH_URLS = AUTH_URLS;
  submitting = false;

  public loadingStatus = new LoadingStatus();

  constructor(
    private commonUIService: CommonUIService,
    public authService: AuthService,
  ) { }

  ngOnInit() {
    this.loadingStatus.loaded();
  }

  submit(ev){
    ev.preventDefault();
    this.resetPassword();
  }

  resetPassword() {
    let email = this.emailRef.nativeElement.value;

    if (!email) {
      this.commonUIService.displayMessage('Error', 'Please enter email.')
      return;
    }

    this.loadingStatus.loading();
    this.authService.sendPasswordResetEmail({email: email}).subscribe(res => {
      this.loadingStatus.loaded();
      const errors = utils.formUtils.getErrors(res);
        if (errors.length > 0) {
          this.commonUIService.displayMessage('Error', errors.join(' '));
        } else {
          this.commonUIService.displayMessage('Nearly there', 'Password reset email sent, check your inbox.');
        }
    });
  }

}
