import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { AuthService } from "@app/auth/services/auth.service";
import { AUTH_URLS } from "@app/auth/auth-urls";
import { Router } from "@angular/router";

@Component({
  selector: "app-login-status",
  templateUrl: "./login-status.component.html",
  styles: [],
})
export class LoginStatusComponent implements OnInit {
  @Output() onCloseMenu = new EventEmitter();

  AUTH_URLS = AUTH_URLS;
  constructor(public authService: AuthService, private router: Router) {}

  ngOnInit() {}

  logout() {}

  closeMenu(link) {
    this.onCloseMenu.emit();
    this.router.navigate([link]);
  }
}
