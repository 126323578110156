export enum DropzoneFileStatus {
  Ready, // 0
  Uploading, // 1
  Completed, // 2
  Failed, // 3
  Ignored, // 4
}

export interface DropzoneFile {
  fileId: string,
  fileName: string,
  fileType: string,
  filePathInServer: string,
  size: number,
  sizeText: string,
  ext: string,
  status: DropzoneFileStatus,
  percentComplete: number,
  fileObject: any,
  thumbnail: string,
  errors: string[]
}

export interface DropzoneData {
  files: DropzoneFile[],
  filterErrors: string[]
}

