import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ProductService {

  private url = environment.apiEndpoint + '/api/ProductAdmin';
  constructor(private http: HttpClient) { }
  getProducts (): Observable<any[]> {
    return this.http.get<any[]>(this.url + "/get")
      .pipe();
  }

  getCategoryProducts (categoryId): Observable<any[]> {
    return this.http.get<any[]>(this.url + "/getCategoryProducts/" + categoryId)
      .pipe();
  }

  getProduct (productId: string): Observable<any[]> {
    return this.http.get<any[]>(this.url + "/getProduct/" + productId)
      .pipe();
  }

  saveProduct(product: any): Observable<any>  {
    return this.http.post(this.url + "/saveProduct", product)
      .pipe();
  }

  deleteProduct(productId: any): Observable<any>  {
    return this.http.post(this.url + "/removeProduct", {productId: productId})
      .pipe();
  }


  saveProductFormula(product: any): Observable<any>  {
    return this.http.post(this.url + "/saveProductFormula", product)
      .pipe();
  }

  saveProductFeedback(feedback: any): Observable<any>  {
    return this.http.post(this.url + "/saveProductFeedback", feedback)
      .pipe();
  }

  removeProductFeedback(feedback: any): Observable<any>  {
    return this.http.post(this.url + "/removeProductFeedback", feedback)
      .pipe();
  }
}
