import { Component, OnInit, Input } from '@angular/core';
import * as routerUtils from '../../../utils/router';
import { Router } from '@angular/router'

@Component({
  selector: 'j6-button',
  templateUrl: './button.component.html',
  styles: []
})
export class ButtonComponent implements OnInit {
  @Input() href: any;
  @Input() text: any;
  isExternal = false;
  constructor(public router: Router) { }

  ngOnInit() {
    var rt = routerUtils.getLink(this.href);
    this.isExternal = rt && !rt.internal;
  }

  clickHandler(e) {
    var rt = routerUtils.getLink(this.href);
    //console.log(rt);
    if (rt) {
      if (rt.internal) {
        this.router.navigate([rt.url]);
      } else {
        window.location.href = rt.url;
      }
    }
  }
}
