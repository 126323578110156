import { Component, OnInit, Input } from '@angular/core';
import { CmsItemUtils } from '../../utils/item-utils';
import { Router } from '@angular/router'

@Component({
  selector: 'app-cms-single-banner',
  templateUrl: './cms-single-banner.component.html',
  styleUrls: ['./cms-single-banner.component.scss']
})
export class CmsSingleBannerComponent implements OnInit {
  @Input() item: any;
  @Input() index: number;
  @Input() cmsOption: any;
  id: string;
  constructor(public router: Router) { }

  ngOnInit() {
  }

  payload(key: string) {
    return CmsItemUtils.getPayload(this.item, key);
  }

  cssClass() {
    return [this.item.cssClass || this.item.anchorName || '', this.payload('HeightSet') || ''];
  }

  handleClick(ev) {
    console.log(ev);
    var link = this.payload('LinkUrl');
    if (!this.cmsOption.isCmsEdit && link) {
      if (link.indexOf('/') === 0) {
        // internal link
        this.router.navigate([link]);
      } else {
        if (link.indexOf('http') !== 0) {
          link = 'http://' + link;
        }
        window.open(link, '_blank');
      }
    }
  }
}
