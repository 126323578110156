import { Component, OnInit, Input } from '@angular/core';
import { CommonDataService } from '../../services/common-data.service';

@Component({
  selector: 'app-product-tile',
  templateUrl: './product-tile.component.html',
  styleUrls: ['./product-tile.component.scss']
})
export class ProductTileComponent implements OnInit {

  constructor(public store: CommonDataService) { }
  @Input() product: any;
  ngOnInit() {
  }

}
