import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { ProductService } from '../../services/product.service';
import { CategoryService } from '../../../services/category.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})

export class ProductsComponent implements OnInit {
  displayedColumns: string[] = ['ProductName','Formula', 'Price', 'ProductStatusText', 'DateUpdated'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  products: any[];
  categories: any[];
  totalCnt=-1;

  constructor(
    private categoryService: CategoryService,
    private productService: ProductService) { }

  ngOnInit() {
    this.getCategories();
    this.getProducts();
    this.dataSource.sort = this.sort;
  }

  getProducts(): void {
    this.productService.getProducts()
    .subscribe(data => {
      //console.log(data);
      this.products = data
      this.dataSource.data = data;
      this.totalCnt = data? data.length : 0;
    });
  }

  getCategoryProducts(categoryId): void {
    this.productService.getCategoryProducts(categoryId)
    .subscribe(data => {
      //console.log(data);
      this.products = data
      this.dataSource.data = data;
      this.totalCnt = data? data.length : 0;
    });
  }

  getCategories(): void {
    this.categoryService.getCategories()
    .subscribe(data => {
      this.categories = data
    });
  }

  filterProducts(ev) {
    // console.log('filtering', ev)
    if (ev.value)
      this.getCategoryProducts(ev.value);
    else
      this.getProducts();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  totalCountHtml() {
    return this.totalCnt >= 0 ? '(' + this.totalCnt + ')' : 'Loading...';
  }

}
