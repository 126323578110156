import { Component, OnInit, Input } from '@angular/core';
import * as dateUtils from '../../utils/datetime';
import * as textUtils from '../../utils/text';

@Component({
  selector: 'app-order-detail-partial',
  templateUrl: './order-detail-partial.component.html',
  styles: []
})
export class OrderDetailPartialComponent implements OnInit {
  formatDateTime = dateUtils.formatDateTime;
  formatMoney = textUtils.formatMoney;

  @Input() order: any;
  @Input() forAdmin: boolean = false;

  gmapShipping = null;
  gmapBilling = null;
  constructor() { }

  ngOnInit() {
    console.log(this.forAdmin)

    if (!this.isEmptyAddress(this.order.shippingAddress)) {
      this.gmapShipping =
      'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(this.getAddress(this.order.shippingAddress));
    }
    if (!this.order.useSameAddress && !this.isEmptyAddress(this.order.billingAddress)) {
      this.gmapBilling =
      'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(this.getAddress(this.order.billingAddress));
    }
  }

  isEmptyAddress(addr) {
    return !addr || !(addr.addressLine || addr.suburb || addr.state || addr.postcode);
  }

  getAddress(addr) {
    return addr.addressLine + ', ' + addr.suburb + ', ' + addr.state + ', ' + addr.postcode + ' Australia';
  }
}
