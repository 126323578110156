import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../../services/category.service';
import { ProductService } from '../../services/product.service';
import { MetaService } from '../../services/meta.service';
import { CommonDataService } from '../../services/common-data.service';
import { LoadingStatus } from '../../common/loading-status';

@Component({
  selector: 'app-category-page',
  templateUrl: './category-page.component.html',
  styleUrls: ['./category-page.component.scss']
})
export class CategoryPageComponent implements OnInit {
  id: string;
  model: any;
  bannerUrl: string;
  idSubscriber: Subscription;

  constructor(
    private metaService: MetaService,
    public loadingStatus: LoadingStatus,
    private categoryService: CategoryService,
    private productService: ProductService,
    public store: CommonDataService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.idSubscriber = this.route.params
      .subscribe(
        (params: { Params }) => {
          let id = params['id'];
          // console.log(id);
          this.getCategory(id);
        });
    // this.id = this.route.snapshot.paramMap.get('id');
    // this.getCategory(this.id);
    this.bannerUrl = this.getBanner();
  }

  cartAction(product) {
    return this.productService.getAction(product);
  }

  getCategory(id): void {
    this.categoryService.getCategory(id)
      .subscribe(pageData => {
        console.log(pageData);
        this.model = pageData.data;
        this.metaService.addMetaTags({
          pageTitle: this.model.categoryName,
          payloads: [
            { key: 'Keyword', value: '' },
            { key: 'ImageUrl', value: this.model.imageUrl },
            { key: 'Description', value: this.model.shortDesc }
          ]
        });
        this.store.setPageData(pageData);

        this.loadingStatus.loaded();
      },
        err => {
          this.loadingStatus.showErrorMessage('Fail to load page information', err.message, err);
        });
  }

  getBanner() {
    var images = ['herb-collage1920-narrow-01.jpg', 'herb-collage1920-narrow-02.jpg', 'herb-collage1920-narrow-03.jpg'];
    return '/sites/skyezone/media/images/banners/' +  images[Math.floor(Math.random()*images.length)];
  }
}
