import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort, MatTableDataSource } from '@angular/material';
import { FeedbackService } from '../../services/feedback.service';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-feedbacks',
  templateUrl: './feedbacks.component.html',
  styles: []
})
export class FeedbacksComponent implements OnInit {
  idSubscriber: Subscription;

  displayedColumns: string[] = ['feedbackDate', 'name', 'source', 'feedbackContent', 'dateCreated', 'dateUpdated'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  feedbacks= []
  totalCnt=0;

  constructor(
    private route: ActivatedRoute,
    private feedbackService: FeedbackService) { }

  ngOnInit() {
    this.idSubscriber = this.route.queryParams
      .subscribe(
        (params: { Params }) => {
          this.getFeedbacks();
          this.dataSource.sort = this.sort;
        });

  }

  getFeedbacks(): void {
    this.feedbackService.getFeedbacks()
    .subscribe(data => {
      //console.log(data);
      this.feedbacks = data
      this.dataSource.data = data;
      this.totalCnt = data? data.length : 0;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
}
