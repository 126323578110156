import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

import { AuthService } from "@app/auth/services/auth.service";
import { Router } from "@angular/router";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    newRequest: HttpHandler
  ): Observable<HttpEvent<any>> {
    return newRequest.handle(request).pipe(
      catchError((err) => {
        console.log("error interceptor", err);
        if (err.status === 401) {
          //if 401 response returned from api, logout from application & redirect to login page.
          this.router.navigateByUrl("/auth/logout");
        } else if (err.status === 500) {
          if (err.error.indexOf("No User") >= 0)
            this.router.navigateByUrl("/auth/logout");
        }

        //const error = (err.error && err.error.message) || err.statusText;
        return throwError(err);
      })
    );
  }
}
