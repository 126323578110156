import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';

@Injectable()
export class PromptUpdateService {

  constructor(swUpdate: SwUpdate, private snackbar: MatSnackBar) {
    if (!swUpdate.isEnabled) {
      console.log('Nope');
    }

    swUpdate.available.subscribe(event => {

      const snack = this.snackbar.open('New Update Available', 'Reload App', {
        // duration: 3000
      });
      snack
        .onAction()
        .subscribe(() => {
          swUpdate.activateUpdate().then(() => document.location.reload());
        });

      // if (this.promptUser(event)) {
      //   swUpdate.activateUpdate().then(() => document.location.reload());
      // }
    });
  }

  // promptUser(event) {
  //   return confirm("This application has an update. Do you want to install it?");
  // }
}
