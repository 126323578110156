import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeedbackService } from '../../services/feedback.service';
import { environment } from '../../../../environments/environment';
// import { MatTabChangeEvent } from '@angular/material/tabs';
import * as ngFormUtils from '../../../utils/ng-form';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-feedback-edit',
  templateUrl: './feedback-edit.component.html',
  styles: []
})
export class FeedbackEditComponent implements OnInit {
  feedback: any = { feedbackId: null };
  feedbackForm: FormGroup;
  feedbackId: any;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private feedbackService: FeedbackService,
    public dialog: MatDialog) {
    this.createForm();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.feedbackId = id;
    console.log(id);
    if (id) {
      this.getFeedback(id);
    }
  }
  //#region form
  createForm() {
    this.feedbackForm = this.fb.group({
      feedbackId: '',
      feedbackContent: ['', Validators.required],
      name: '',
      source: '',
      feedbackDate: ['', Validators.required]
    });
  }

  onSubmit() {
    ngFormUtils.markFormGroupTouched(this.feedbackForm);
    console.log(this.feedbackForm);
    if (this.feedbackForm.invalid) return;

    var model = this.feedbackForm.value;
    console.log(model);
    this.feedbackService.saveFeedback(model)
      .subscribe(data => {
        this.router.navigate(['/admin/feedback']);
      });
  }

  delete() {
    if (confirm('Remove this feedback?')) {
      this.feedbackService.removeFeedback(this.feedbackId)
      .subscribe(data => {
        this.router.navigate(['/admin/feedback']);
      });
    }
  }

  rebuildForm(feedback) {
    this.feedbackForm.reset(feedback);
  }

  //#endregion

  //#region api

  getFeedback(id: string): void {
    this.feedbackService.getFeedback(id)
      .subscribe((data: any) => {
        console.log(data);
        this.feedback = data;
        this.rebuildForm(this.feedback);
      });
  }

  //#endregion

}
