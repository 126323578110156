import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
@Component({
  selector: 'app-product-edit-feedback',
  templateUrl: './product-edit-feedback.component.html',
  styleUrls: ['./product-edit-feedback.component.scss']
})
export class ProductEditFeedbackComponent  implements OnInit {
  formisvalid = false;
  constructor(
    public dialogRef: MatDialogRef<ProductEditFeedbackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  onSubmit() {
    this.dialogRef.close(this.data);
  }
}
