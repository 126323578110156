import { Component, OnInit, Input } from '@angular/core';
import { CommonDataService } from '../../services/common-data.service';
import { ProductService } from '../../services/product.service';
import { SearchService } from '../../services/search.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { LoadingStatus } from '../../common/loading-status';
import { CommonErrorStateMatcher } from '../../common/error-status-matcher';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styles: []
})
export class SearchComponent implements OnInit {
  searchForm: FormGroup;
  searchResult: any;
  categories = [];
  matcher = new CommonErrorStateMatcher();

  constructor(
    private fb: FormBuilder,
    public loadingStatus: LoadingStatus,
    private productService: ProductService,
    public searchService: SearchService,
    public store: CommonDataService) {
      this.createForm();
    }

  ngOnInit() {
    this.search(null);
  }

  createForm() {
    this.searchForm = this.fb.group({
      keyword: '',
      categoryId: ''
    });
  }

  cartAction(product) {
    return this.productService.getAction(product);
  }

  search(ev): void {
    var opt = this.searchForm.value;
    this.searchService.search(opt)
      .subscribe(searchResult => {
        console.log(searchResult);
        this.searchResult = searchResult;
        this.categories = searchResult.categories;

        this.loadingStatus.loaded();
      });
  }
}
