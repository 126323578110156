import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CommonData } from '../entities/common-data';

@Injectable({
  providedIn: 'root'
})
export class CommonDataService {

  private dataSubject = new BehaviorSubject<CommonData>(new CommonData());
  data = this.dataSubject.asObservable();
  constructor() {}

  setData(data: CommonData) {
    this.dataSubject.next(data);
  }

  getData() {
    return this.dataSubject.getValue();
  }

  isCmsEdit() {
    var store = this.getData();
    return store && store.isCmsEdit;
  }

  // getMenu(fn) {
  //   var store = this.getData();
  //   return store && store.menu;
  // }

  setPageData(pageData) {
    if (!pageData) return;
    var store: CommonData = this.getData() || new CommonData();
    store.menu = pageData.menu;

    if (pageData.cartInfo) {
      store.badges['cart'] = pageData.cartInfo.numberOfItems;
    } else {
      store.badges['cart'] = 0;
    }

    //console.log(store)
    this.setData(store);
  }

  setMenu(menu) {
    var store: CommonData = this.getData() || new CommonData();
    store.menu = menu;
    //console.log(store)
    this.setData(store);
  }

  setBadge(name, value) {
    var store: CommonData = this.getData() || new CommonData();
    store.badges[name] = value;
    this.setData(store);
  }
}
