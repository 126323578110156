import { Component, OnInit, Input, ViewChild, ElementRef} from '@angular/core';
import { CmsItemUtils } from '../../utils/item-utils';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
@Component({
  selector: 'app-cms-code',
  templateUrl: './cms-code.component.html',
  styleUrls: ['./cms-code.component.scss']
})
export class CmsCodeComponent implements OnInit {
  @Input() item: any;
  @Input() index: number;
  @Input() cmsOption: any;
  
  //@ViewChild('scriptContent', {read: ElementRef}) scriptContent: ElementRef;
  id: string;
  html: SafeHtml;

  constructor(
    private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }
  //<script type="text/javascript" src="https://sealserver.trustwave.com/seal.js?code=271ecbbc6cbe4f8cab8d6aa80d098ec1"></script>
//   ngAfterViewInit() {
//     setTimeout(() => {
//       console.log(this.scriptContent.nativeElement, this.scriptContent.nativeElement.innerHtml)
//       let node = document.createElement('script');
//             node.src = 'https://sealserver.trustwave.com/seal.js?code=271ecbbc6cbe4f8cab8d6aa80d098ec1';
//             node.type = 'text/javascript';
//             node.async = false;
//             node.charset = 'utf-8';
//             this.scriptContent.nativeElement.appendChild(node);
//         //this.scriptContent.nativeElement.innerHtml = this.sanitizer.bypassSecurityTrustHtml(this.payload('Code'));

//         console.log(this.scriptContent.nativeElement, this.scriptContent.nativeElement.innerHtml)
//     }, 0);
// }
  payload(key: string) {
    return CmsItemUtils.getPayload(this.item, key);
  }

  get code() {
    if (this.item && this.payload('CodeType') == 'Json') {
      return JSON.parse(this.payload('Code'));
    } else {
      return { control: 'text', text: this.sanitizer.bypassSecurityTrustHtml(this.payload('Code')) };
    }
  }
}
