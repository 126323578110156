import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Message } from '../entities/message';

@Component({
  selector: 'app-message-popup',
  templateUrl: './message-popup.component.html',
  styles: []
})
export class MessagePopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<MessagePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Message) { }

  ngOnInit() {
  }

}
