import { Component, OnInit } from '@angular/core';
import { AuthService } from "../services/auth.service";
import { auth } from 'firebase';
import { AngularFireAuth } from "@angular/fire/auth";

@Component({
  selector: 'app-login-status',
  templateUrl: './login-status.component.html',
  styles: []
})
export class LoginStatusComponent implements OnInit {
  constructor(
    public authService: AuthService,
    public afAuth: AngularFireAuth
  ) { }

  ngOnInit() {
    const user = this.afAuth.auth.currentUser;
    console.log(user);
  }

  extendSession() {
    const userInStorage = this.authService.currentUser;
    console.log(userInStorage);
    const user = this.afAuth.auth.currentUser;
    console.log(user);
    //this.authService.setUser(user);
  }
}
