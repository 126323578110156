import { Routes } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { ProductComponent } from "./pages/product/product.component";
import { CategoryPageComponent } from "./pages/category-page/category-page.component";
import * as cms from "./cms/cms-pages";

export const PUBLIC_ROUTES: Routes = [
  { path: "login", redirectTo: "auth/login", pathMatch: "full" },
  { path: "logout", redirectTo: "auth/logout", pathMatch: "full" },
  { path: "category/:id", component: CategoryPageComponent },
  { path: "product/:id", component: ProductComponent },
  { path: "cms/page/render/:id", component: cms.EditPageComponent },
  { path: "", component: cms.ViewPageComponent },
  { path: "**", component: cms.ViewPageComponent },
];

// export const CMS_FALLBACK_ROUTES: Routes = [
//   { path: "**", component: cms.ViewPageComponent },
// ];
