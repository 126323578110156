import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-product-edit-option',
  templateUrl: './product-edit-option.component.html',
  styleUrls: ['./product-edit-option.component.scss']
})
export class ProductEditOptionComponent implements OnInit {
  formisvalid = false;
  constructor(
    public dialogRef: MatDialogRef<ProductEditOptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
  }

  onSubmit() {
    this.dialogRef.close(this.data);
  }
}
