import { Action } from '@ngrx/store';

export enum CmsActionTypes {
  SetPage = '[Cms] SetPage',
  SetMenu = '[Cms] SetMenu',
  SetMasqueradingUser = 'MasqueradingUser'
}

export class SetPage implements Action {
  readonly type = CmsActionTypes.SetPage;
  constructor(public payload: { page: any }) {}
}

export class SetMenu implements Action {
  readonly type = CmsActionTypes.SetMenu;
  constructor(public payload: { menu: any }) {}
}

export class SetMasqueradingUser implements Action {
  readonly type = CmsActionTypes.SetMasqueradingUser;
  constructor(public payload: { masqueradingUser: any }) {}
}

export type CmsActions =
  | SetPage
  | SetMenu
  | SetMasqueradingUser;
