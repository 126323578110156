export const AUTH_URLS = {
  onAuthSuccessUrl: '/member',
  onAuthFailureUrl: '/auth/login',
  signInUrl: '/auth/login',
  loginUrl: '/auth/login',
  logoutUrl: '/auth/logout',
  signUpUrl: '/auth/signup',
  forgotPasswordUrl: '/auth/forgot-password',
  verifyEmailUrl: 'auth/verify-email-address'
}
