import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { environment } from '../../../../environments/environment';
// import { MatTabChangeEvent } from '@angular/material/tabs';
import * as ngFormUtils from '../../../utils/ng-form';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styles: []
})
export class ContactEditComponent implements OnInit {
  contact: any = { contactId: null, contactType: '' };
  contactForm: FormGroup;
  contactId: any;
  contactType: any;
  gmapAddress = null;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private contactService: ContactService,
    public dialog: MatDialog) {
    this.createForm();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    this.contactId = id;
    console.log(id);
    if (id == 'client' || id == 'retail' || id == 'supplier') {
      this.contact.contactType = id;
      this.contactType = this.contact.contactType;
      console.log(this.contact);
      this.rebuildForm(this.contact);
    }
    else if (id) {
      this.getContact(id);
    }
  }
  //#region form
  createForm() {
    this.contactForm = this.fb.group({
      contactId: '',
      contactType: ['', Validators.required],
      contactName: ['', Validators.required],
      skinType: '',
      favourite: '',
      phone: '',
      fax: '',
      email: '',
      web: '',
      street: '',
      suburb: '',
      town: '',
      state: '',
      postcode: '',
      country: '',
      notes: ''
    });
  }

  onSubmit() {
    ngFormUtils.markFormGroupTouched(this.contactForm);
    if (this.contactForm.invalid) return;

    var model = this.contactForm.value;
    console.log(model);
    this.contactService.saveContact(model)
      .subscribe(data => {
        this.router.navigate(['/admin/contacts'], { queryParams: { type: model.contactType } });
      }
    /* error handling */);
    //this.rebuildForm();
  }

  delete() {
    if (confirm('Remove this contact?')) {
      this.contactService.removeContact(this.contactId)
      .subscribe(data => {
        this.router.navigate(['/admin/contacts'], { queryParams: { type: this.contactType } });
      });
    }
  }

  rebuildForm(contact) {
    this.contactForm.reset(contact);

    if (!this.isEmptyAddress(contact)) {
      this.gmapAddress =
      'https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(this.getAddress(contact));
    }
  }

  isEmptyAddress(addr) {
    return !addr || !(addr.street || addr.suburb || addr.town || addr.state || addr.postcode);
  }

  getAddress(addr) {
    return addr.street + ', ' + addr.suburb + ', ' + + addr.town + ', ' + addr.state + ', ' + addr.postcode + (addr.country ? addr.country : ' Australia');
  }
  //#endregion

  //#region api

  getContact(id: string): void {
    this.contactService.getContact(id)
      .subscribe((data: any) => {
        console.log(data);
        this.contact = data;
        this.contactType = this.contact.contactType;
        this.rebuildForm(this.contact);
      });
  }

  //#endregion

}
