import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  private endpoint = environment.apiEndpoint;
  constructor(private http: HttpClient) {}

  addToCart(cartItem: any): Observable<any> {
    return this.http.post(this.endpoint + "/api/Cart/AddToCart", cartItem);
  }

  getCart(): Observable<any> {
    return this.http.get(this.endpoint + "/api/Cart/GetCart");
  }

  removeOrderItem(orderItemId: any): Observable<any> {
    return this.http.post(this.endpoint + "/api/Cart/RemoveOrderItem", {
      orderItemId: orderItemId,
    });
  }

  saveCartItems(savingCart: any): Observable<any> {
    console.log(savingCart);
    return this.http.post(
      this.endpoint + "/api/Cart/SaveOrderItems2",
      savingCart
    );
  }

  saveCartShippingInfo(info: any): Observable<any> {
    console.log(info);
    return this.http.post(this.endpoint + "/api/Cart/SaveShippingInfo", info);
  }

  pay(info: any): Observable<any> {
    console.log(info);
    return this.http.post(this.endpoint + "/api/Cart/Pay", info);
  }

  getPaidOrder(orderToken): Observable<any> {
    return this.http.get(
      this.endpoint + "/api/Order/GetPaidOrder/" + orderToken
    );
  }

  getPastOrder(orderId): Observable<any> {
    return this.http.get(this.endpoint + "/api/Order/GetPastOrder/" + orderId);
  }
}
