import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class CheckForUpdateService {

  constructor(updates: SwUpdate) {
    console.log('CheckForUpdateService', new Date())
    interval(1 * 60 * 60 * 1000).subscribe(() => updates.checkForUpdate());
  }
}
