import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  private endpoint = environment.apiEndpoint;
  constructor(private http: HttpClient) { }

  getProduct(id): Observable<any> {
    return this.http.get<any>(this.endpoint + '/api/Product/' + id)
      .pipe();
  }

  getAction(product) {
    // console.log(product.productStatus)
    if (product) {
      if (product.productStatus == 0) {
        return { action: 'cart', status: 'On Sale'};
      } else if (product.productStatus == 1) {
        return { action: 'message', status: 'For Hire'};
      } else if (product.productStatus == 11) {
        return { action: 'message', status: 'Not Available'};
      } else if (product.productStatus == 12) {
        return { action: 'message', status: 'Sold Out'};
      } else if (product.productStatus == 21) {
        return { action: 'message', status: 'Enquiry Only'};
      } else if (product.productStatus == 99) {
        return { action: 'message', status: 'Not Available'};
      }
    }
    return { action: 'message', status: 'Not Available'};
  }
}
