import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  private url = environment.apiEndpoint + '/api/Feedback';
  constructor(private http: HttpClient) { }
  getFeedbacks(): Observable<any[]> {
    return this.http.get<any[]>(this.url + "/GetList");
  }
}
