import { Component, OnInit, AfterViewInit, Input, ViewChild, Renderer2, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { CmsItemUtils } from '../../utils/item-utils';
import * as cssUtils from '../../../utils/css';
import { Router } from '@angular/router';
import * as routerUtils from '../../../utils/router';
import * as uiUtils from '../../../utils/ui';

@Component({
  selector: 'app-cms-html',
  templateUrl: './cms-html.component.html',
  styleUrls: ['./cms-html.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsHtmlComponent implements OnInit, AfterViewInit {
  @Input() item: any;
  @Input() index: number;
  @Input() cmsOption: any;
  @ViewChild('htmlContent', {read: ElementRef}) htmlContent: ElementRef;
  @ViewChild('htmlContent2', {read: ElementRef}) htmlContent2: ElementRef;
  constructor(
    public router: Router,
    private renderer: Renderer2,
    private sanitizer: DomSanitizer) { }
  html: SafeHtml;
  sectionTitle: any;
  sectionExpand: any;
  anchor: any;

  ngOnInit() {
    this.setModel();
    var content = this.payload('Content');
    //console.log(content, this.cmsOption);
    if (this.cmsOption.isCmsEdit)
      this.html = this.sanitizer.bypassSecurityTrustHtml(content);
  }

  ngAfterViewInit() {
    this.setModel();
    var content = this.payload('Content');
    //console.log(content, this.cmsOption);
    if (!this.cmsOption.isCmsEdit) {
      this.filteredHtml(content);
    }
      //this.renderer.appendChild(this.htmlContent.nativeElement, filtered);
  }

  setModel() {
    this.sectionTitle = this.payload('SectionTitle');
    this.sectionExpand = this.payload('SectionExpand');
    this.anchor = this.item.anchorName || "";
  }

  filteredHtml(htmlContent) {
    //console.log(htmlContent);
    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(htmlContent, 'text/html');
    //console.log(parsedHtml);
    let aTags = parsedHtml.getElementsByTagName("a");
    //console.log(aTags);
    for (var i = aTags.length - 1; i >= 0; i--) {
      let a = aTags[i];
      // console.log(a);

      if (cssUtils.hasClass(a, 'button')) {
        let button = document.createElement('j6-button');
        //console.log(a.innerHTML);
        button.setAttribute('text', a.innerHTML)
        button.setAttribute('href', a.href)
        button.innerHTML = a.innerHTML;
        this.replaceNode(button, a);
        //this.insertAfter(button, a);
        //document.removeChild(a);

      }
    }

    var doc = document.createElement('div');
    var tags = parsedHtml.getElementsByTagName('body')[0].childNodes;

    var elements = []
    for (var i = 0; i < tags.length; i++) {
      let tag = tags[i];
      elements.push(tag)

    }

    //console.log(parsedHtml.getElementsByTagName('body')[0].childNodes)
    for (var i = 0; i < elements.length; i++) {
      let tag = elements[i];
      //console.log(tag)
      doc.appendChild(tag)

    }


    //return parsedHtml.getElementsByTagName('body')[0];
    if (this.sectionTitle)
      this.renderer.appendChild(this.htmlContent2.nativeElement, doc);
    else
      this.renderer.appendChild(this.htmlContent.nativeElement, doc);
  }

  replaceNode(newEl, oldEl) {
    oldEl.parentNode.replaceChild(newEl, oldEl);
  }

  insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }

  insertBefore(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode);
  }


  // html() {
  //   var content = this.payload('Content');
  //   return this.sanitizer.bypassSecurityTrustHtml(content);
  // }

  payload(key: string) {
    return CmsItemUtils.getPayload(this.item, key);
  }

  cssClass() {
    return this.item.cssClass || this.item.anchorName || "";
  }

  clickHandler(ev) {
    let $link = $(ev.target).closest('a');
    if ($link.length == 0) return;
    let link = $link.get(0);
    if (link.getAttribute('href') && link.getAttribute('href').indexOf('#') == 0) {
      let processed = uiUtils.scrollTo(link.getAttribute('href'));
      if (processed) {
        ev.preventDefault();
      }
    }
    var rt = routerUtils.getLink(link.getAttribute('href'));
    if (rt && rt.internal) {
      ev.preventDefault();
      this.router.navigate([rt.url]);
    }

  }
}
