import { Component, OnInit, Input } from '@angular/core';
import { CmsItemUtils } from '../../cms/utils/item-utils';
import { CommonDataService } from '../../services/common-data.service';
import { Category } from '../../entities/category';
import { CategoryService } from '../../services/category.service';
import * as arrayUtils from '../../utils/array';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  @Input() item: any;
  @Input() index: number;
  @Input() cmsOption: any;

  categories: Category[];
  constructor(
    private categoryService: CategoryService,
    public store: CommonDataService) { }

  ngOnInit() {
    this.getCategories();
  }

  payload(key: string) {
    return CmsItemUtils.getPayload(this.item, key);
  }

  getCategories(): void {
    this.categoryService.getCategories()
    .subscribe(productCategories => {
      var selectedCategories = this.payload('SelectedCategories')
      if (selectedCategories) {
        var selectedCategoriesArray = selectedCategories.split(',');
        this.categories = productCategories.filter( x => arrayUtils.exists(selectedCategoriesArray, id => id == x.categoryId))
      } else {
        this.categories = productCategories
      }
    });
  }

}
