import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute, ParamMap, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { ItemComponent } from '../../modules/item/item.component';
import { PageService } from '../../services/page.service';
import { MetaService } from '../../../services/meta.service';
import { CommonDataService } from '../../../services/common-data.service';
import { ScriptService } from '../../../services/script.service';
import { CmsPageController } from '../../cms-page-controller';
import { LoadingStatus } from '../../../common/loading-status';

declare var $j5: any;
declare var cmsPage: any;

@Component({
  selector: 'app-view-page',
  templateUrl: './view-page.component.html',
  styleUrls: ['./view-page.component.scss']
})

export class ViewPageComponent implements OnInit, OnDestroy {
  idSubscriber: Subscription;
  page: any;
  data: any;
  cmsOption: any;
  cmsPageController: CmsPageController;
  pageNotFound = false;
  constructor(
    private metaService: MetaService,
    public loadingStatus: LoadingStatus,
    private route: ActivatedRoute,
    private pageService: PageService,
    private scriptService: ScriptService,
    private commonDataService: CommonDataService) { }

  ngOnInit() {
    this.idSubscriber = this.route.url
    .subscribe(
      (url:any) => {
        // console.log(url)
        // var path = this.route.params['path'];
        // console.log('path - ' + path);
        // if (path == null) {
        //   //console.log('/' + this.route.snapshot.url.join('/'))
        //   path = this.route.snapshot['_routerState'].url;
        // }
        // console.log('path - ' + path);
        var path = this.route.snapshot['_routerState'].url;

        this.loadPage(path);
        this.cmsOption = {isCmsEdit : false};
        this.data = this.commonDataService.getData();

        this.cmsPageController = new CmsPageController();
      });
  }
  ngOnDestroy() {
    this.idSubscriber.unsubscribe();
  }
  loadPage(path: string): void {
    this.pageService.getPublishedPage(path)
      .subscribe(pageData => {
        if (pageData && pageData.data) {
          this.pageNotFound = false;
          console.log(pageData);
          this.page = pageData.data;
          this.metaService.addMetaTags(this.page);


        } else {
          this.pageNotFound = true;
        }
        this.commonDataService.setPageData(pageData);
        this.loadingStatus.loaded();
      },
      err => {
        this.loadingStatus.showErrorMessage('Fail to load page information', err.message, err);
      });
  }
}
