import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map, tap } from "rxjs/operators";
import { Observable, empty, of } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class MemberService {
  constructor(private http: HttpClient) {}

  getDashboard(): Observable<any> {
    return this.http.get<any>("/api/dashboard/getDashboard");
  }

  getProfile(): Observable<any> {
    return this.http.get<any>("/api/member/getProfile");
  }

  saveProfile(model): Observable<any> {
    return this.http.post<any>("/api/member/saveProfile", model);
  }

  saveAddress(model): Observable<any> {
    return this.http.post<any>("/api/profile/saveAddress", model);
  }

  removeAddress(model): Observable<any> {
    return this.http.post<any>("/api/profile/removeAddress", model);
  }

  reOrder(model): Observable<any> {
    return this.http.post<any>("/api/member/reOrder", model);
  }
}
