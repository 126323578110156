import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class MemberAdminService {
  private url = environment.apiEndpoint + "/api/MemberAdmin";
  constructor(private http: HttpClient) {}
  getMembers(): Observable<any[]> {
    return this.http.get<any[]>(this.url + "/GetList");
  }
  getMember(id): Observable<any> {
    return this.http.get<any>(this.url + "/getMember/" + id);
  }

  deleteMember(id): Observable<any> {
    return this.http.get<any>(this.url + "/deleteMember/" + id);
  }

  resetPassword(model): Observable<any> {
    return this.http.post<any>("/api/MemberAdmin/ResetPassword", model);
  }
}
