export class CmsItemUtils {
  static getPayload(item: any, key: string, def: any = '') {
    //console.log(item, key, def);
    if (!item || !item.payloads) return def;

    for (var i = 0; i < item.payloads.length; i++) {
      if (key == item.payloads[i].key)
      return item.payloads[i].value
    }

    return def
  }

  // static getPayload(item: any, key: string) {
  //   if (!item) return '';

  //   var p = {}
  //   for (var i = 0; i < item.payloads.length; i++) {
  //     p[item.payloads[i].key] = item.payloads[i].value
  //   }

  //   return p
  // }
}
