import * as moment from "moment";

// $utils.date.formatDate()
export const formatDate = (date) => {
  console.log(date);
  return moment(date).format("DD/MM/YYYY");
};

// $utils.date.formatDateForList()
export const formatDateForList = (date) => {
  return moment(date).format("YYYY-MM-DD");
};

// $utils.date.formatDateTime()
export const formatDateTime = (date) => {
  return moment(date).format("DD/MM/YYYY hh:mm A");
};

// $utils.date.addDays()
export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const convertToUTC = (date) => {
  if (!date) return null;
  const dt = new Date(date);
  return new Date(
    Date.UTC(dt.getFullYear(), dt.getMonth(), dt.getDate())
  ).toISOString();
};
