import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  @Input() item: any;
  @Input() index: number;
  @Input() cmsOption: any;
  constructor() { }

  ngOnInit() {
  }

  in(types: Array<string>) {
    //console.log(types)
    if (this.item == null) return false;
    if (types.find(x => this.item.itemTypeCode == x))
      return true;
    else
      return false;
  }
}
