import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  private url = environment.apiEndpoint + "/api/OrderAdmin";
  constructor(private http: HttpClient) {}
  getOrders(filter): Observable<any[]> {
    return this.http.get<any[]>(
      this.url + "/GetList?startFrom=" + filter.startFrom
    );
  }
  getOrder(id): Observable<any> {
    return this.http.get<any>(this.url + "/GetPaidOrder/" + id);
  }
  resendReceipt(resend: any): Observable<any> {
    return this.http.post(this.url + "/ResendReceipt", resend);
  }
  resendAdminNotice(resend: any): Observable<any> {
    return this.http.post(this.url + "/ResendAdminNotice", resend);
  }
}
