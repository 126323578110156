import { Routes } from "@angular/router";
import { ProductsComponent } from "./pages/products/products.component";
import { ProductEditComponent } from "./pages/product-edit/product-edit.component";
import { OrdersComponent } from "./pages/orders/orders.component";
import { OrderViewComponent } from "./pages/order-view/order-view.component";
import { CategoriesComponent } from "./pages/categories/categories.component";
import { CategoryEditComponent } from "./pages/category-edit/category-edit.component";
import { ContactsComponent } from "./pages/contacts/contacts.component";
import { ContactEditComponent } from "./pages/contact-edit/contact-edit.component";
import { FeedbacksComponent } from "./pages/feedbacks/feedbacks.component";
import { FeedbackEditComponent } from "./pages/feedback-edit/feedback-edit.component";
import { FormulaEditComponent } from "./pages/formula-edit/formula-edit.component";
import { ProductOrderInCategoryComponent } from "./pages/product-order-in-category/product-order-in-category.component";
import { MembersComponent } from "./pages/members/members.component";
import { MemberAdminViewComponent } from "./pages/member-view/member-view.component";

export const ADMIN_ROUTES: Routes = [
  { path: "", redirectTo: "products", pathMatch: "full" },
  { path: "products", component: ProductsComponent },
  { path: "product/add", component: ProductEditComponent },
  { path: "product/edit/:id", component: ProductEditComponent },
  { path: "orders", component: OrdersComponent },
  { path: "order/view/:id", component: OrderViewComponent },
  { path: "categories", component: CategoriesComponent },
  { path: "category/add", component: CategoryEditComponent },
  { path: "category/edit/:id", component: CategoryEditComponent },
  {
    path: "category/product-order/:id",
    component: ProductOrderInCategoryComponent,
  },
  { path: "contacts", component: ContactsComponent },
  { path: "contact/edit/:id", component: ContactEditComponent },
  { path: "feedback", component: FeedbacksComponent },
  { path: "feedback/add", component: FeedbackEditComponent },
  { path: "feedback/edit/:id", component: FeedbackEditComponent },
  { path: "formula/edit/:id", component: FormulaEditComponent },
  { path: "member/view/:id", component: MemberAdminViewComponent },
  { path: "members", component: MembersComponent },
];
