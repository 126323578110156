import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import * as utils from '@app/shared/utils';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent implements OnInit {
  year = 2019;
  ver = '1';
  constructor() { }

  ngOnInit() {
    this.year = (new Date()).getFullYear();
    this.ver = environment.ver;
  }

  goToTop(e){
    e.preventDefault();
    utils.uiUtils.scrollTo('#top');
  }
}
