import { Component, OnInit, Input } from "@angular/core";
import { CommonDataService } from "../../services/common-data.service";
import { ContactService } from "../../services/contact.service";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { LoadingStatus } from "../../common/loading-status";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
import { CommonErrorStateMatcher } from "../../common/error-status-matcher";
import { hasError, markFormGroupTouched } from "../../utils/ng-form";
import { convertToUTC } from "@app/utils/datetime";

@Component({
  selector: "app-subscribe",
  templateUrl: "./subscribe.component.html",
  styles: [],
})
export class SubscribeComponent implements OnInit {
  model: any;
  formGroup: FormGroup;
  matcher = new CommonErrorStateMatcher();
  hasError = hasError;
  submitting = false;

  constructor(
    private fb: FormBuilder,
    public loadingStatus: LoadingStatus,
    public contactService: ContactService,
    public store: CommonDataService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    // this.createForm();
    this.loadFormData();
  }

  createForm() {
    this.formGroup = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      state: ["", Validators.required],
      gender: [null, Validators.required],
      dob: ["", Validators.required],
      productCategories: [null, [Validators.required]],
      skinType: [null, [Validators.required]],
    });
  }

  loadFormData() {
    this.contactService.getSubscriptionFormData().subscribe((x) => {
      console.log(x);
      this.model = x;

      this.createForm();
      // this.formGroup.reset(x);
    });
  }

  onSubmit(): void {
    markFormGroupTouched(this.formGroup);
    if (this.formGroup.invalid) return;

    this.submitting = true;
    var opt = this.formGroup.value;
    opt.dob = convertToUTC(opt.dob);
    this.contactService.subscribeNewsletter(opt).subscribe((contactResult) => {
      console.log(contactResult);

      this.submitting = false;
      let config = new MatSnackBarConfig();
      //config.verticalPosition = 'top';
      config.duration = 3000;
      this.snackBar.open(
        "Successfully subscribed. Thank you.",
        "Close",
        config
      );

      this.formGroup.reset({
        name: "",
        email: "",
        phone: "",
        enquiry: "",
      });
    });
  }
}
