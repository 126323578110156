import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormArray,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { OrderService } from "../../services/order.service";
import { ScriptService } from "../../../services/script.service";
import { environment } from "../../../../environments/environment";
// import { MatTabChangeEvent } from '@angular/material/tabs';
import * as arrayUtils from "../../../utils/array";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig,
} from "@angular/material";

@Component({
  selector: "app-order-view",
  templateUrl: "./order-view.component.html",
  styles: [],
})
export class OrderViewComponent implements OnInit {
  order: any;
  resendEmail: string;
  memberId: string;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private orderService: OrderService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get("id");
    this.memberId = this.route.snapshot.queryParamMap.get("memberId");
    if (id) {
      this.getOrder(id);
    }
  }

  getOrder(id: string): void {
    this.orderService.getOrder(id).subscribe((data: any) => {
      console.log(data);
      this.order = data;
      try {
        this.resendEmail = this.order.useSameAddress
          ? this.order.shippingAddress.email
          : this.order.billingAddress.email;
      } catch (e) {}
    });
  }

  resend() {
    if (!this.resendEmail) return;
    if (!confirm("Send the invoice email again?")) return;
    const model = {
      orderId: this.order.orderId,
      email: this.resendEmail,
    };
    this.orderService.resendReceipt(model).subscribe(
      (data) => {
        let config = new MatSnackBarConfig();
        //config.verticalPosition = 'top';
        config.duration = 3000;

        this.snackBar.open("Successfully sent.", "Close", config);
        //this.router.navigate(['/admin/products']);
      }
      /* error handling */
    );
  }

  resendAdmin() {
    if (!this.resendEmail) return;
    if (!confirm("Send the invoice email again?")) return;
    const model = {
      orderId: this.order.orderId,
      email: this.resendEmail,
    };
    this.orderService.resendAdminNotice(model).subscribe(
      (data) => {
        let config = new MatSnackBarConfig();
        //config.verticalPosition = 'top';
        config.duration = 3000;

        this.snackBar.open("Successfully sent.", "Close", config);
        //this.router.navigate(['/admin/products']);
      }
      /* error handling */
    );
  }
}
