import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MaterialModule } from "@app/material.module";

import { CoreModule } from "../core/core.module";
// import { CmsModule } from "../cms/cms.module";
import { AuthModule } from "../auth/auth.module";

import { TopHeaderComponent } from "./top-header/top-header.component";
import { FooterComponent } from "./footer/footer.component";
// import { BottomDockComponent } from './bottom-dock/bottom-dock.component';
import { LoginStatusComponent } from "./login-status/login-status.component";
import { AppBasedLayoutComponent } from "./app-based-layout/app-based-layout.component";
import { AppBasedEmptyLayoutComponent } from "./app-based-layout.empty/app-based-layout.component";

// import { CommonBottomPartialComponent } from './common-bottom-partial/common-bottom-partial.component';
// import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { PublicLayoutComponent } from "./public-layout/public-layout.component";
// import { MemberLayoutComponent } from './member-layout/member-layout.component';
import { AppCommonBannerComponent } from "./app-common-banner/app-common-banner.component";
import { BottomMenuComponent } from "./bottom-menu/bottom-menu.component";
import { AdminLayoutComponent } from "../admin/layout/admin-layout/admin-layout.component";

@NgModule({
  declarations: [
    TopHeaderComponent,
    AppBasedLayoutComponent,
    AppBasedEmptyLayoutComponent,
    PublicLayoutComponent,
    AppCommonBannerComponent,
    FooterComponent,
    TopHeaderComponent,
    LoginStatusComponent,
    BottomMenuComponent,
    AdminLayoutComponent,
    PublicLayoutComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    RouterModule,
    AuthModule,
    MaterialModule,
    // CmsModule,
  ],
  exports: [
    TopHeaderComponent,
    LoginStatusComponent,
    FooterComponent,
    AppBasedLayoutComponent,
    AppBasedEmptyLayoutComponent,
  ],
})
export class LayoutModule {}
