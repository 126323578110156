import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSort,
  MatTableDataSource,
} from "@angular/material";
import { Router } from "@angular/router";
import { CommonUIService } from "@app/common-ui/services/common-ui.service";
import { MemberService } from "@app/member/services/member.service";
import { CommonDataService } from "@app/services/common-data.service";

@Component({
  selector: "app-memberadmin-orders",
  templateUrl: "./member-orders.component.html",
  styles: [],
})
export class MemberAdminOrdersComponent implements OnInit {
  @Input() model: any;

  displayedColumns: string[] = [
    "orderDate",
    "orderNo",
    "totalAmount",
    "shippingMethod",
    "orderItems",
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  orders = [];

  constructor(
    private commonUIService: CommonUIService,
    private memberSerice: MemberService,
    public store: CommonDataService,
    public snackBar: MatSnackBar,
    public router: Router
  ) {}

  ngOnInit() {
    this.dataSource.data = this.model.pastOrders;
    this.dataSource.sort = this.sort;
  }
}
