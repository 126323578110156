import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { CommonErrorStateMatcher } from "@app/core/error-status-matcher";
import { MemberService } from "@app/member/services/member.service";

@Component({
  selector: "app-select-address-popup",
  templateUrl: "./select-address-popup.component.html",
  styleUrls: ["./select-address-popup.scss"],
})
export class SelectAddressPopupComponent implements OnInit {
  errorMessage: string;

  constructor(
    public dialogRef: MatDialogRef<SelectAddressPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    console.log(this.data);
  }

  select(address) {
    this.errorMessage = "";
    this.dialogRef.close(address);
  }

  cancel() {
    this.dialogRef.close();
  }
}
