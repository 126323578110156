export class CommonData {
  isCmsEdit: boolean;
  menu: any;
  badges: any;

  constructor() {
    this.isCmsEdit = false;
    this.menu = null
    this.badges = {
      cart: 0
    };
  }

}
