import { Component, OnInit } from '@angular/core';
import { CommonDataService } from '../../services/common-data.service';

@Component({
  selector: 'app-bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss']
})
export class BottomMenuComponent implements OnInit {
  badges: any;
  constructor(private store: CommonDataService) {
    store.data.subscribe(
      data => {
        this.badges = data.badges;
    });
  }

  ngOnInit() {
  }

  get cartNum(): number {
    if (this.badges) {
      return this.badges.cart;
    }
    return 0;
  }
}
