import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
import { Router } from "@angular/router";
import { MemberAdminService } from "@app/admin/services/member.service";
import { CommonUIService } from "@app/common-ui/services/common-ui.service";
import { MemberService } from "@app/member/services/member.service";
import { CommonDataService } from "@app/services/common-data.service";
import * as ngFormUtils from "@app/utils/ng-form";

@Component({
  selector: "app-member-password",
  templateUrl: "./member-password.component.html",
  styles: [],
})
export class MemberAdminPasswordComponent implements OnInit {
  @Input() model: any;
  formGroup: FormGroup;

  constructor(
    private commonUIService: CommonUIService,
    private memberSerice: MemberAdminService,
    private fb: FormBuilder,
    public store: CommonDataService,
    public snackBar: MatSnackBar,
    public router: Router
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      password: ["", Validators.required],
    });
  }

  onSubmit() {
    ngFormUtils.markFormGroupTouched(this.formGroup);
    if (this.formGroup.invalid) return;

    var val = this.formGroup.value;
    this.memberSerice
      .resetPassword({ userId: this.model.userId, password: val.password })
      .subscribe(
        (data) => {
          let config = new MatSnackBarConfig();
          config.duration = 3000;

          this.snackBar.open("Successfully reset.", "Close", config);
        }
        /* error handling */
      );
    //this.rebuildForm();
  }
}
