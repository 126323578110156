import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from "@app/auth/services/auth.service";
import { Router, ActivatedRoute } from '@angular/router'

import { AUTH_URLS } from "@app/auth/auth-urls";
import { LoadingStatus } from '@app/common-ui/loading-status';
import * as utils from '@app/shared/utils';
import { CommonUIService } from '@app/common-ui/services/common-ui.service';

@Component({
  selector: 'app-verify-email-server',
  templateUrl: './verify-email-server.component.html',
  styles: []
})
export class VerifyEmailServerComponent implements OnInit {
  AUTH_URLS = AUTH_URLS;
  verified = false;
  token: string;
  progressMessage = "Updating profile...";

  public loadingStatus = new LoadingStatus();
  constructor(
    private commonUIService: CommonUIService,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.token = params['u'];

      if (!this.token) {
        this.progressMessage = "This is not a valid URL for verification. Please follow the link from your email.";
        this.loadingStatus.loaded();
      } else {
        this.verify()
      }
    });
  }

  verify() {
    if (!this.token) {
      this.commonUIService.displayMessage('Error', 'This is not a valid URL for verification. Please follow the link from your email.')
      return;
    }

    this.loadingStatus.loading();
    this.authService.verificationMail({ token: this.token }).subscribe(
      res => {
        this.loadingStatus.loaded();
        const errors = utils.formUtils.getErrors(res);
        if (errors.length > 0) {
          this.commonUIService.displayMessage('Error', errors.join(' '));
        } else {
          this.verified = true;
        }
      },
      err => {
        this.loadingStatus.loaded();
        this.commonUIService.displayMessage('Error', (err.error && err.error.message) || err.message || err.status);
        console.error(err);
      });
  }

}
