import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";

import { MemberService } from "@app/member/services/member.service";
import { StyleHelper } from "@jaba/j6-ng-utils";

import { MatSnackBar, MatDialog } from "@angular/material";
// import { ProfileImagePopupComponent } from '../profile-image-popup/profile-image-popup.component';
import { ProfileEditPopupComponent } from "../profile-edit-popup/profile-edit-popup.component";
import { AddressEditPopupComponent } from "../address-edit-popup/address-edit-popup.component";
import * as utils from "@app/shared/utils";

@Component({
  selector: "app-dashboard-profile",
  templateUrl: "./dashboard-profile.component.html",
  styleUrls: ["./dashboard-profile.scss"],
})
export class DashboardProfileComponent implements OnInit {
  @Input() model: any;
  @Output() reload = new EventEmitter();

  formatDate = utils.formatDateAU;
  constructor(
    public dialog: MatDialog,
    public styleHelper: StyleHelper,
    public router: Router,
    private profileService: MemberService
  ) {}

  ngOnInit() {}

  // changePhoto() {
  //   const dialogRef = this.dialog.open(ProfileImagePopupComponent, {
  //     //width: '250px',
  //     data: {},
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log(`Dialog result: ${result}`);
  //     if (result) {
  //       this.reload.emit();
  //     }
  //   });
  // }

  editAddress(address) {
    const dialogRef = this.dialog.open(AddressEditPopupComponent, {
      width: utils.getPopupWidth(),
      data: address,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.reload.emit();
      }
    });
  }

  updateProfile() {
    this.router.navigate(["/member/profile"]);
  }
  // getUrl(slug) {
  //   return environment.preferredUrl + "/profile/" + slug;
  // }
}
