import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MemberService } from "@app/member/services/member.service";

import { AuthService } from "@app/auth/services/auth.service";
import { CommonDataService } from "@app/services/common-data.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styles: [],
})
export class DashboardComponent implements OnInit {
  model: any;
  constructor(
    private profileService: MemberService,
    public authService: AuthService,
    public router: Router,
    private commonDataService: CommonDataService
  ) {}

  ngOnInit() {
    this.loadDashboard();
  }

  loadDashboard() {
    this.profileService.getDashboard().subscribe((x) => {
      console.log(x);
      this.model = x;

      const pageData = x.pageData;
      if (pageData && pageData.menu) {
        // console.log(pageData.menu);
        this.commonDataService.setMenu(pageData.menu);
      }
    });
  }

  readyToUse() {
    if (this.model && this.model.profileName && this.model.selectedLoc)
      return true;
    return false;
  }
}
