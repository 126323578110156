import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PromptUpdateService } from "./services/prompt-update.service";
import { CheckForUpdateService } from "./services/check-for-update.service";
import { CookieService } from "ngx-cookie-service";

import { SlickModule } from "ngx-slick";
import { SortablejsModule } from "angular-sortablejs";

import { AuthModule } from "./auth/auth.module";
import { LayoutModule } from "./layout/layout.module";

import { AuthService } from "@app/auth/services/auth.service";
import { AuthGuard } from "@app/core/guards/auth.guard";
import { TokenInterceptor } from "@app/core/interceptors/tokenInterceptor";
import { ErrorInterceptor } from "@app/core/interceptors/errorInterceptor";

import { StoreModule } from "@ngrx/store";
import { reducers, metaReducers } from "./store";
import { EffectsModule } from "@ngrx/effects";
import { AppEffects } from "./app.effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";

import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { RequestInterceptor } from "./services/request-interceptor";

// import { TopHeaderComponent } from "./layout/top-header/top-header.component";
import { ProductComponent } from "./pages/product/product.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { HomeComponent } from "./pages/home/home.component";
import { CategoryComponent } from "./modules/category/category.component";
import { CategoryListComponent } from "./modules/category-list/category-list.component";
import { CategoryPageComponent } from "./pages/category-page/category-page.component";
import { EditPageComponent } from "./cms/pages/edit-page/edit-page.component";
import { ItemComponent } from "./cms/modules/item/item.component";
import { SectionComponent } from "./cms/modules/section/section.component";
import { CmsPanelComponent } from "./cms/items/cms-panel/cms-panel.component";
import { CmsHtmlComponent } from "./cms/items/cms-html/cms-html.component";
// import { BottomMenuComponent } from "./layout/bottom-menu/bottom-menu.component";
import { CmsBannerPanelComponent } from "./cms/items/cms-banner-panel/cms-banner-panel.component";
import { CmsBannerItemComponent } from "./cms/items/cms-banner-item/cms-banner-item.component";
import { ViewPageComponent } from "./cms/pages/view-page/view-page.component";
// import { AdminLayoutComponent } from "./admin/layout/admin-layout/admin-layout.component";
import { ProductsComponent } from "./admin/pages/products/products.component";
import { MaterialModule } from "./material.module";

// Common
// import { AuthGuard } from './common/auth.guard';
import { LoadingStatus } from "./common/loading-status";
// import { PublicLayoutComponent } from "./layout/public-layout/public-layout.component";
import { CategoriesComponent } from "./admin/pages/categories/categories.component";
import { OrdersComponent } from "./admin/pages/orders/orders.component";
import { ProductEditComponent } from "./admin/pages/product-edit/product-edit.component";
import { ProductEditOptionComponent } from "./admin/pages/product-edit-option/product-edit-option.component";
import { ProductEditFeedbackComponent } from "./admin/pages/product-edit-feedback/product-edit-feedback.component";
import { AppVersionComponent } from "./modules/app-version/app-version.component";
import { CmsCodeComponent } from "./cms/items/cms-code/cms-code.component";
import { ProductTileComponent } from "./modules/product-tile/product-tile.component";
import { CmsSingleBannerComponent } from "./cms/items/cms-single-banner/cms-single-banner.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { CartComponent } from "./modules/cart/cart.component";
import { LoadingComponent } from "./modules/loading/loading.component";
import { AddressEditorComponent } from "./modules/address-editor/address-editor.component";
import { CmsPageTileComponent } from "./cms/items/cms-page-tile/cms-page-tile.component";
import { ButtonComponent } from "./cms/partials/button/button.component";
import { CategoryEditComponent } from "./admin/pages/category-edit/category-edit.component";
import { SearchComponent } from "./modules/search/search.component";
import { ContactComponent } from "./modules/contact/contact.component";
import { OrderDetailComponent } from "./modules/order-detail/order-detail.component";
import { OrderViewComponent } from "./admin/pages/order-view/order-view.component";
import { OrderDetailPartialComponent } from "./modules/order-detail-partial/order-detail-partial.component";
import { ContactsComponent } from "./admin/pages/contacts/contacts.component";
import { ContactEditComponent } from "./admin/pages/contact-edit/contact-edit.component";
import { FeedbacksComponent } from "./admin/pages/feedbacks/feedbacks.component";
import { FeedbackEditComponent } from "./admin/pages/feedback-edit/feedback-edit.component";
import { FormulaEditComponent } from "./admin/pages/formula-edit/formula-edit.component";
import { MembersComponent } from "./admin/pages/members/members.component";

import { FeedbackListComponent } from "./modules/feedback-list/feedback-list.component";
import { ProductOrderInCategoryComponent } from "./admin/pages/product-order-in-category/product-order-in-category.component";
import { ErrorMessageComponent } from "./modules/error-message/error-message.component";
import { SuggestLoginComponent } from "./modules/cart/suggest-login.component";

import { DashboardComponent } from "./member/components/dashboard/dashboard.component";
import { DashboardOrdersComponent } from "./member/components/dashboard-orders/dashboard-orders.component";
import { DashboardProfileComponent } from "./member/components/dashboard-profile/dashboard-profile.component";
import { ProfileEditPopupComponent } from "./member/components/profile-edit-popup/profile-edit-popup.component";
import { AddressEditPopupComponent } from "./member/components/address-edit-popup/address-edit-popup.component";
import { PastOrderViewComponent } from "./member/components/orderview/orderview.component";

import { SelectAddressPopupComponent } from "./modules/select-address-popup/select-address-popup.component";
import { MemberAdminViewComponent } from "./admin/pages/member-view/member-view.component";
import { MemberAdminOrdersComponent } from "./admin/pages/member-view/member-orders/member-orders.component";
import { MemberAdminPasswordComponent } from "./admin/pages/member-view/member-password/member-password.component";
import { SubscribeComponent } from "./modules/subscribe/subscribe.component";
import { ProfileEditComponent } from "./member/components/profile-edit/profile-edit.component";

@NgModule({
  declarations: [
    AppComponent,
    ProductComponent,
    HomeComponent,
    CategoryComponent,
    CategoryListComponent,
    CategoryPageComponent,
    EditPageComponent,
    ItemComponent,
    SectionComponent,
    CmsPanelComponent,
    CmsHtmlComponent,
    CmsBannerPanelComponent,
    CmsBannerItemComponent,
    ViewPageComponent,
    ProductsComponent,
    CategoriesComponent,
    OrdersComponent,
    ProductEditComponent,
    ProductEditOptionComponent,
    ProductEditFeedbackComponent,
    AppVersionComponent,
    CmsCodeComponent,
    ProductTileComponent,
    CmsSingleBannerComponent,
    PageNotFoundComponent,
    CartComponent,
    LoadingComponent,
    AddressEditorComponent,
    CmsPageTileComponent,
    ButtonComponent,
    CategoryEditComponent,
    SearchComponent,
    ContactComponent,
    OrderDetailComponent,
    OrderViewComponent,
    OrderDetailPartialComponent,
    ContactsComponent,
    ContactEditComponent,
    FeedbacksComponent,
    FeedbackEditComponent,
    FormulaEditComponent,
    FeedbackListComponent,
    ProductOrderInCategoryComponent,
    MembersComponent,
    MemberAdminViewComponent,
    MemberAdminOrdersComponent,
    MemberAdminPasswordComponent,
    SubscribeComponent,
    ProfileEditComponent,

    ErrorMessageComponent,
    SuggestLoginComponent,
    DashboardComponent,
    DashboardProfileComponent,
    DashboardOrdersComponent,
    PastOrderViewComponent,
    ProfileEditPopupComponent,
    AddressEditPopupComponent,
    SelectAddressPopupComponent,
  ],
  entryComponents: [
    ProductEditOptionComponent,
    ProductEditFeedbackComponent,
    AddressEditorComponent,
    ButtonComponent,
    ProfileEditPopupComponent,
    AddressEditPopupComponent,
    SelectAddressPopupComponent,
  ],
  imports: [
    AuthModule,
    LayoutModule,
    SlickModule.forRoot(),
    SortablejsModule.forRoot({ animation: 150 }),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    ServiceWorkerModule.register("/ngsw-worker.js", {
      enabled: environment.production,
    }),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot([AppEffects]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
  ],
  exports: [OrderDetailPartialComponent],
  providers: [
    AuthService,
    AuthGuard,
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: RequestInterceptor,
    //   multi: true,
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    LoadingStatus,
    PromptUpdateService,
    CheckForUpdateService,
    CookieService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
