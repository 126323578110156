import { Component, OnInit, Input } from '@angular/core';
import { CmsItemUtils } from '../../utils/item-utils';

@Component({
  selector: 'app-cms-panel',
  templateUrl: './cms-panel.component.html',
  styleUrls: ['./cms-panel.component.scss']
})
export class CmsPanelComponent implements OnInit {
  @Input() item: any;
  @Input() index: number;
  @Input() cmsOption: any;
  id: string;
  constructor() { }

  ngOnInit() {

  }

  payload(key: string) {
    return CmsItemUtils.getPayload(this.item, key);
  }

  cssClass() {
    return this.item.cssClass || this.item.anchorName || "";
  }

  backgroundImage() {
    if (this.payload('ImageUrl'))
      return `url('` + this.payload('ImageUrl') + `')`;
    if (this.payload('BackgroundTile'))
      return `url('/dist/transparent-textures/textures/` + this.payload('BackgroundTile') + `')`;
    return null;
  }
}
