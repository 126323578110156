import { Injectable, Optional } from '@angular/core';

@Injectable()
export class LoadingStatus {
  status: string;
  error: string;
  errorDetails: string;

  constructor() {
    this.loading();
    // console.log(initStatus)
    // if (initStatus == 'hide'){
    //   this.hide();
    // } else if (initStatus == 'loaded'){
    //   this.loaded();
    // } else {
    //   this.loading();
    // }
  }

  showErrorMessage(errMsg, detailMessage, errObj) {
    this.error = 'Error - ' + errMsg;
    this.errorDetails = detailMessage;
    console.log(errObj);
  }

  loading() {
    this.status = 'loading';
    this.resetErrors();
  }

  loaded() {
    this.status = 'loaded';
    this.resetErrors();
  }

  hide() {
    this.status = 'hide';
    this.resetErrors();
  }

  resetErrors() {
    this.error = null
    this.errorDetails = null;
  }

  isLoaded() {
    return this.status == 'loaded';
  }

  isLoading() {
    return this.status == 'loading';
  }
}
