import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatDialog } from '@angular/material';
import { MessagePopupComponent } from '../message-popup/message-popup.component';

@Injectable({
  providedIn: 'root'
})
export class CommonUIService {

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar) { }

  displayMessage(title, message, handler = null) {
    const dialogRef = this.dialog.open(MessagePopupComponent, {
      //width: '250px',
      data: { type: 'error', title: title, message: message }
    });

    dialogRef.afterClosed().subscribe(handler);
  }

  snackBarMessage(message) {
    let config = new MatSnackBarConfig();
    config.duration = 3000;
    this.snackBar.open(message, 'Close', config);
  }
}
