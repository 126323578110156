import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { CmsItemUtils } from '../cms/utils/item-utils';

@Injectable({
  providedIn: 'root'
})
export class MetaService {

  constructor(private meta: Meta, private title: Title) { }

  addMetaTags(page) {
    this.meta.removeTag('name = "description"');
    this.meta.removeTag('name = "keywords"');
    this.meta.removeTag('name = "language"');
    this.meta.removeTag('name = "country"');
    this.meta.removeTag('property = "og:title"');
    this.meta.removeTag('property = "og:image"');

    this.title.setTitle(page.pageTitle);

    this.meta.addTag({ name: 'description', content: this.payload(page, 'Description') });
    this.meta.addTag({ name: 'keywords', content: this.payload(page, 'Keyword') });
    this.meta.addTag({ name: 'language', content: 'English' });
    this.meta.addTag({ name: 'country', content: 'Australia' });

    this.meta.addTag({property: 'og:title', content: page.pageTitle});
    // this.meta.addTag({property: 'og:url', content: page.pageUrl});

    var imageUrl = this.payload(page, 'ImageUrl');
    if (imageUrl)
    this.meta.addTag({property: 'og:image', content: imageUrl});

  }

  payload(page, key: string) {
    return CmsItemUtils.getPayload(page, key);
  }
}
