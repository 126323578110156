import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from "@app/auth/services/auth.service";
import { AUTH_URLS } from "@app/auth/auth-urls";
import { LoadingStatus } from '@app/common-ui/loading-status';
import * as utils from '@app/shared/utils';
import { CommonUIService } from '@app/common-ui/services/common-ui.service';

@Component({
  selector: 'app-verify-email-address',
  templateUrl: './verify-email-address.component.html',
  styles: []
})
export class VerifyEmailAddressComponent implements OnInit {
  AUTH_URLS = AUTH_URLS;
  submitting = false;

  public loadingStatus = new LoadingStatus();

  constructor(
    private commonUIService: CommonUIService,
    public authService: AuthService
  ) { }

  ngOnInit() {
    this.loadingStatus.loaded();
  }

  resend() {
    this.authService.sendVerificationMail().subscribe(
      res => {
        this.loadingStatus.loaded();
        const errors = utils.formUtils.getErrors(res);
        if (errors.length > 0) {
          this.commonUIService.displayMessage('Error', errors.join(' '));
        } else {
          this.commonUIService.displayMessage('Success', 'Successfully resent verification email.');
        }
      },
      err => {
        this.loadingStatus.loaded();
        this.commonUIService.displayMessage('Error', (err.error && err.error.message) || err.message || err.status);
        console.error(err);
      });
  }
}
