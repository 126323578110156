import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { LoadingStatus } from '../loading-status';

@Component({
  selector: 'app-loading-full',
  templateUrl: './loading-full.component.html',
  styles: []
})
export class LoadingFullComponent implements OnInit {
  @Input() loadingStatus: LoadingStatus;
  constructor() {}

  ngOnInit() {
  }

}
