import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreModule } from '@app/core/core.module';
import { LoadingComponent } from './loading/loading.component';
import { MaterialModule } from "../material.module";
import { J6NgUtilsModule } from "@jaba/j6-ng-utils";

import { MessagePopupComponent } from './message-popup/message-popup.component';
import { CommonUIService } from './services/common-ui.service';
import { DropzoneComponent } from './dropzone/dropzone.component';
import { LoadingFullComponent } from './loading-full/loading-full.component';

@NgModule({
  declarations: [LoadingComponent, MessagePopupComponent, DropzoneComponent, LoadingFullComponent],
  imports: [
    CommonModule,
    MaterialModule,
    CoreModule,
    J6NgUtilsModule
  ],
  entryComponents: [
    MessagePopupComponent
  ],
  exports: [
    LoadingComponent,
    LoadingFullComponent,
    MessagePopupComponent,
    DropzoneComponent
  ]
})
export class CommonUIModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CommonUIModule,
      providers: [CommonUIService]
    };
  }
}
