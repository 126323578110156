import { Component, Input, OnInit, ViewChild } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSort,
  MatTableDataSource,
} from "@angular/material";
import { Router } from "@angular/router";
import { CommonUIService } from "@app/common-ui/services/common-ui.service";
import { MemberService } from "@app/member/services/member.service";
import { CommonDataService } from "@app/services/common-data.service";

@Component({
  selector: "app-dashboard-orders",
  templateUrl: "./dashboard-orders.component.html",
  styleUrls: ["./dashboard-orders.component.scss"],
})
export class DashboardOrdersComponent implements OnInit {
  @Input() model: any;

  displayedColumns: string[] = [
    "orderDate",
    "orderNo",
    "totalAmount",
    "shippingMethod",
    "orderItems",
    "actions",
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  orders = [];

  constructor(
    private commonUIService: CommonUIService,
    private memberSerice: MemberService,
    public store: CommonDataService,
    public snackBar: MatSnackBar,
    public router: Router
  ) {}

  ngOnInit() {
    this.dataSource.data = this.model.pastOrders;
    this.dataSource.sort = this.sort;
  }

  reOrder(orderId) {
    if (
      !confirm(
        "This will reset your shopping cart and redirect you to the cart. Proceed?"
      )
    )
      return;

    this.memberSerice.reOrder({ orderId: orderId }).subscribe((data) => {
      console.log(data);
      if (data.errors && data.errors.length > 0) {
        this.commonUIService.displayMessage(
          "Error",
          data.errors[0].errorMessage
        );
      } else if (data && data.results && data.results.cartInfo) {
        this.store.setBadge("cart", data.results.cartInfo.numberOfItems);
        let config = new MatSnackBarConfig();
        //config.verticalPosition = 'top';
        config.duration = 3000;
        this.snackBar.open("Successfully copied", "Close", config);
        this.router.navigate(["/cart"]);
      }
    });
  }
}
