import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  private url = environment.apiEndpoint + '/api/ContactAdmin';
  constructor(private http: HttpClient) { }
  getContacts(type): Observable<any[]> {
    return this.http.get<any[]>(this.url + "/GetList/" + type);
  }

  getContact(id): Observable<any> {
    return this.http.get<any>(this.url + "/GetContact/" + id);
  }

  saveContact(contact: any): Observable<any>  {
    return this.http.post(this.url + "/SaveContact", contact);
  }

  removeContact(id): Observable<any>  {
    return this.http.get(this.url + "/RemoveContact/" + id);
  }
}
