import { Component, OnInit } from '@angular/core';
import { AuthControlService } from "@app/auth/services/auth-control.service";
import { AUTH_URLS } from "@app/auth/auth-urls";

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styles: []
})
export class SignOutComponent implements OnInit {
  AUTH_URLS = AUTH_URLS;

  constructor(
    public authControlService: AuthControlService
  ) { }

  ngOnInit() {
    this.authControlService.signOut();
  }

}
