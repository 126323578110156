import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { CommonErrorStateMatcher } from "@app/core/error-status-matcher";
import { MemberService } from "@app/member/services/member.service";

@Component({
  selector: "app-profile-edit-popup",
  templateUrl: "./profile-edit-popup.component.html",
  styles: [],
})
export class ProfileEditPopupComponent implements OnInit {
  formGroup: FormGroup;
  matcher = new CommonErrorStateMatcher();
  errorMessage: string;

  constructor(
    private fb: FormBuilder,
    private profileService: MemberService,
    public dialogRef: MatDialogRef<ProfileEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.createForm();
    this.loadData(this.data);
  }

  createForm() {
    console.log(this.data);
    this.formGroup = this.fb.group({
      firstName: ["", Validators.required],
      surname: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", Validators.required],
      addressLine: ["", Validators.required],
      suburb: ["", Validators.required],
      state: ["", Validators.required],
      postcode: ["", Validators.required],
      country: "",
    });
  }

  loadData(model) {
    this.formGroup.reset(model);
  }

  onSubmit() {
    this.errorMessage = "";
    console.log(this.formGroup.value);
    if (this.formGroup.invalid) return;

    let model = Object.assign({}, this.formGroup.value);

    this.profileService.saveProfile(model).subscribe((data) => {
      console.log(data);
      this.dialogRef.close(true);
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}
