import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { ItemComponent } from '../../modules/item/item.component';
import { PageService } from '../../services/page.service';
import { CommonDataService } from '../../../services/common-data.service';
import { ScriptService } from '../../../services/script.service';
import { CmsPageController } from '../../cms-page-controller';
import { LoadingStatus } from '../../../common/loading-status';

declare var $j5: any;
declare var cmsPage: any;

@Component({
  selector: 'app-edit-page',
  templateUrl: './edit-page.component.html',
  styleUrls: ['./edit-page.component.scss']
})

export class EditPageComponent implements OnInit {
  page: any;
  store: any;
  cmsOption: any;
  cmsPageController: CmsPageController;
  constructor(
    public loadingStatus: LoadingStatus,
    private route: ActivatedRoute,
    private pageService: PageService,
    private scriptService: ScriptService,
    private commonDataService: CommonDataService) { }

  ngOnInit() {
    this.cmsOption = {isCmsEdit : true};

    const id = this.route.snapshot.paramMap.get('id');
    const cmsViewMode = this.route.snapshot.queryParamMap.get('cmsViewMode');

    //console.log(id, cmsViewMode);
    this.loadPage(id, cmsViewMode);

    this.store = this.commonDataService.getData();
    this.store.isCmsEdit = true;
    this.commonDataService.setData(this.store);

    this.cmsPageController = new CmsPageController();

    if (cmsViewMode != 'viewonly') {
      const ver = 1;
      this.scriptService.loadJs(
        '/assets/cms/ckeditor/ckeditor.js?v=' + ver
      ).then(data => {
          // console.log('script loaded ', data);
          this.cmsPageController.init({});
          $("body").addClass("cmsedit");
      }).catch(error => console.log(error));
    } else {
      this.cmsOption.isCmsEdit = false;
      this.cmsPageController.init({viewOnly: true});
    }

  }

  loadPage(id: string, cmsViewMode: string): void {

    this.pageService.getPage(id, cmsViewMode)
      .subscribe(pageData => {
        console.log(pageData);
        this.page = pageData.data;
        this.commonDataService.setPageData(pageData);
        this.loadingStatus.loaded();
      },
      err => {
        this.loadingStatus.showErrorMessage('Fail to load page information', err.message, err);
      });
  }

  // resetData() {
  //   // this.data ={isCmsEdit: true};
  //   this.commonDataService.setData({isCmsEdit: true})
  // }
}
