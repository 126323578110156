import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LayoutModule } from "./layout/layout.module";
import { AuthGuard } from "@app/core/guards/auth.guard";
import { AdminLayoutComponent } from "./admin/layout/admin-layout/admin-layout.component";
import { ADMIN_ROUTES } from "./admin/admin-routes";
import { PublicLayoutComponent } from "./layout/public-layout/public-layout.component";

import { AppBasedLayoutComponent } from "./layout/app-based-layout/app-based-layout.component";
import { AppBasedEmptyLayoutComponent } from "./layout/app-based-layout.empty/app-based-layout.component";
import { PUBLIC_ROUTES } from "./public-routes";
import { AUTH_ROUTES } from "@app/auth/auth.routes";

import { DashboardComponent } from "./member/components/dashboard/dashboard.component";
import { PastOrderViewComponent } from "./member/components/orderview/orderview.component";
import { ProfileEditComponent } from "./member/components/profile-edit/profile-edit.component";

const routes: Routes = [
  //{ path: '', redirectTo: 'login', pathMatch: 'full' },

  { path: "auth", component: AppBasedLayoutComponent, children: AUTH_ROUTES },
  {
    path: "admin",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    data: { title: "Admin Views" },
    children: ADMIN_ROUTES,
  },
  {
    path: "member",
    component: AppBasedEmptyLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      // { path: '', component: DashboardComponent },
      { path: "dashboard", component: DashboardComponent },
      { path: "profile", component: ProfileEditComponent },
      { path: "order/:id", component: PastOrderViewComponent },
    ],
    // loadChildren: "./member/member.module#MemberModule",
  },
  {
    path: "",
    component: PublicLayoutComponent,
    data: { title: "Public Views" },
    children: PUBLIC_ROUTES,
  },
  // { path: "", component: PublicLayoutComponent, children: CMS_FALLBACK_ROUTES },
];

@NgModule({
  imports: [LayoutModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
