// $utils.array.findIndex([], o => )
// $utils.array.findIndex(this.model.Photos, o => o.ParkPhotoId === photoId)
export const findIndex = (arr, fn) => {
  if (!arr || arr.length === 0) return -1
  for (var i = 0, l = arr.length; i < l; i++) {
    if (fn(arr[i], i)) {
      return i
    }
  }
  return -1
}

// $utils.array.find([], o => )
export const find = (arr, fn) => {
  var inx = findIndex(arr, fn)
  if (inx >= 0) {
    return arr[inx]
  }
  return null
}

// $utils.array.exists([], o => )
export const exists = (arr, fn) => {
  var inx = findIndex(arr, fn)
  return inx >= 0
}

// $utils.array.removeAt([], 1)
export const removeAt = (arr, inx) => {
  if (!arr || arr.length === 0) return -1
  arr.splice(inx, 1)
}

// $utils.array.remove([], o => )
export const remove = (arr, fn) => {
  var inx = findIndex(arr, fn)
  if (inx >= 0) {
    removeAt(arr, inx)
  }
}

// $utils.array.clear([])
export const clear = (arr) => {
  while (arr.length) {
    arr.pop();
  }
}

// $utils.array.shuffle([])
export function shuffle(array) {
  var i = 0
  var j = 0
  var temp = null

  for (i = array.length - 1; i > 0; i -= 1) {
    j = Math.floor(Math.random() * (i + 1))
    temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}

// -1 (a->b), 1 (b->a)
export function sortCompare(a, b, asc = true, emptyFirst = false) {
  var aval = a === null ? '' : a;
  var bval = b === null ? '' : b;
  if (aval == '' && bval == '')
    return 0;
  else if (aval == '')
    return emptyFirst? -1 : 1;
  else if (bval == '')
    return emptyFirst? 1 : -1;

  else if (aval < bval)
    return asc? -1 : 1;
  else if (aval > bval)
    return asc? 1 : -1;
  else
    return 0;
}

// filter
