import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import * as $ from "jquery";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { pairwise, filter } from "rxjs/operators";
import { CommonDataService } from "../../services/common-data.service";

import { Location } from "@angular/common";
import { isArray } from "util";
import { AuthService } from "@app/auth/services/auth.service";

///import { NavHistoryService } from '../../services/nav-history.service';

@Component({
  selector: "app-top-header",
  templateUrl: "./top-header.component.html",
  styleUrls: ["./top-header.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopHeaderComponent implements OnInit {
  menu: any;
  backUrl = null;
  constructor(
    ///public navHistory: NavHistoryService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private cd: ChangeDetectorRef,
    private store: CommonDataService
  ) {
    this.router.events
      .pipe(filter((e) => e instanceof NavigationEnd))
      .pipe(pairwise())
      .subscribe((e) => {
        // console.log(e);
        if (isArray(e) && e.length > 0) {
          this.backUrl = (e[0] as any).url;
        } else {
          this.backUrl = null;
        }
      });
  }
  ngOnChanges(changes: SimpleChanges) {}
  ngOnInit() {
    //this.backUrl = this.navHistory.getPreviousUrl();
    // console.log("init top-header");
    this.store.data.subscribe((data) => {
      console.log("menu changed", data);
      this.menu = data.menu;
      this.cd.markForCheck();
    });
    //console.log('init top-header', this.location)
    $(window).on("scroll resize", this.initTopLogo);
    this.initTopLogo();
    //this.initHamburger();
  }

  goBack() {
    ///this.navHistory.back();
    // var prev = this.navHistory.pop();
    // //console.log(prev);
    // this.router.navigate([prev]);
    this.location.back();
  }

  // get backUrl():string {
  //   return this.navHistory.getPreviousUrl();
  // }

  openMenu() {
    TopHeaderComponent.togglePanel("#top-menu");
  }

  toggleMenu(item) {
    item.opened = !item.opened;
  }

  initTopLogo() {
    // if ($('body').hasClass('spa')) return
    var $top = $(".top");
    var $img = $top.find(".logo img");

    if ($(window).scrollTop() > $(".top").height()) {
      $(".top-wrapper").addClass("scrolled");
      $img.attr("src", $img.data("scrolled"));
    } else {
      $(".top-wrapper").removeClass("scrolled");
      $img.attr("src", $img.data("org"));
    }
  }

  initHamburger() {
    var self = this;
    $(".hamburger").click(() => TopHeaderComponent.togglePanel("#top-menu"));
  }

  static togglePanel(panelSelector, on = null) {
    var $panel = $(panelSelector);
    if ($("#sliding-menu").hasClass("on") && on !== true) {
      $("html").removeClass("masked");
      $("#sliding-menu").removeClass("on");
      $("#top").removeClass("on");
      $(".hamburger").removeClass("is-active");
    } else {
      $("#sliding-menu > div").hide();
      $panel.show();
      $("html").addClass("masked");
      $("#sliding-menu").addClass("on");
      $("#top").addClass("on");
      $(".hamburger").addClass("is-active");
    }
  }

  onCloseMenu() {
    console.log("close", $("#sliding-menu").hasClass("on"));
    TopHeaderComponent.togglePanel("#top-menu", false);
  }

  static collapse() {
    if ($("#sliding-menu").hasClass("on")) {
      $("html").removeClass("masked");
      $("#sliding-menu").removeClass("on");
      $("#top").removeClass("on");
      $(".hamburger").removeClass("is-active");
    }
  }
}
