import { Component, OnInit, Input } from "@angular/core";
import { CommonDataService } from "../../../services/common-data.service";
import { ContactService } from "../../../services/contact.service";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { LoadingStatus } from "../../../common/loading-status";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material";
import { CommonErrorStateMatcher } from "../../../common/error-status-matcher";
import { hasError, markFormGroupTouched } from "../../../utils/ng-form";
import { MemberService } from "@app/member/services/member.service";
import { Router } from "@angular/router";
import { convertToUTC } from "@app/utils/datetime";

@Component({
  selector: "app-profile-edit",
  templateUrl: "./profile-edit.component.html",
  styles: [],
})
export class ProfileEditComponent implements OnInit {
  model: any;
  formGroup: FormGroup;
  matcher = new CommonErrorStateMatcher();
  hasError = hasError;
  submitting = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public loadingStatus: LoadingStatus,
    public memberService: MemberService,
    public store: CommonDataService,
    public snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    // this.createForm();
    this.loadFormData();
  }

  createForm(formModel) {
    this.formGroup = this.fb.group({
      firstName: [formModel.firstName, Validators.required],
      lastName: [formModel.lastName, Validators.required],
      // email: [formModel.email, [Validators.required, Validators.email]],
      state: [formModel.state, Validators.required],
      gender: [formModel.gender, Validators.required],
      dob: [formModel.dob, Validators.required],
      productCategories: [formModel.productCategories, [Validators.required]],
      skinType: [formModel.skinType, [Validators.required]],
    });
  }

  loadFormData() {
    this.memberService.getProfile().subscribe((x) => {
      console.log(x);
      this.model = x;

      const formModel = { ...x, ...{} };
      this.createForm(formModel);
      // this.formGroup.reset(x);
    });
  }

  onSubmit(): void {
    markFormGroupTouched(this.formGroup);
    if (this.formGroup.invalid) return;

    this.submitting = true;
    var opt = this.formGroup.value;
    console.log(opt.dob);
    opt.dob = convertToUTC(opt.dob);
    console.log(opt.dob);
    this.memberService.saveProfile(opt).subscribe((contactResult) => {
      console.log(contactResult);

      this.submitting = false;
      let config = new MatSnackBarConfig();
      //config.verticalPosition = 'top';
      config.duration = 3000;
      this.snackBar.open("Successfully updated.", "Close", config);

      this.router.navigate(["/member/dashboard"]);
    });
  }
}
