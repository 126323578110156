export const bgUrl = (imageUrl) => {
  return `background-image:url("` + encodeURI(imageUrl) + `")`
}

export const bgUrlStyle = (imageUrl) => {
  return `url("` + imageUrl + `")`
  //return "url(\"" + encodeURI(imageUrl) + "\")"
}


export const screenWidth = () => {
  return window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
}

export const screenHeight = () => {
  return window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
}

export const outerWidth = el => el.offsetWidth
export const outerHeight = el => el.offsetHeight

// export function classList (elt) {
//   var list = elt.classList

//   return {
//     toggle: function (c) { list.toggle(c); return this },
//     add: function (c) { list.add(c); return this },
//     remove: function (c) { list.remove(c); return this }
//   }
// }

export const isMobile = () => {
  var width = screenWidth()
  return width < 500
}

// D:\@Git\_test\vee-validate\src\utils.js
/**
 * Check if element has the css class on it.
 */
export const hasClass = (el, className) => {
  if (el.classList) {
    return el.classList.contains(className)
  }

  return !!el.className.match(new RegExp(`(\\s|^)${className}(\\s|$)`))
}

/**
 * Adds the provided css className to the element.
 */
export const addClass = (el, className) => {
  if (el.classList) {
    el.classList.add(className)
    return
  }

  if (!hasClass(el, className)) {
    el.className += ` ${className}`
  }
}

/**
 * Remove the provided css className from the element.
 */
export const removeClass = (el, className) => {
  if (el.classList) {
    el.classList.remove(className)
    return
  }

  if (hasClass(el, className)) {
    const reg = new RegExp(`(\\s|^)${className}(\\s|$)`)
    el.className = el.className.replace(reg, ' ')
  }
}

/**
 * Adds or removes a class name on the input depending on the status flag.
 */
export const toggleClass = (el, className, status) => {
  if (!el || !className) return

  if (status) {
    return addClass(el, className)
  }

  removeClass(el, className)
}
