import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '../../services/category.service';
import { ScriptService } from '../../../services/script.service';
import { environment } from '../../../../environments/environment';
// import { MatTabChangeEvent } from '@angular/material/tabs';
import * as arrayUtils from '../../../utils/array';
import * as formUtils from '../../../utils/form';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styles: []
})
export class CategoryEditComponent implements OnInit {
  category: any = { categoryId: null };
  categoryForm: FormGroup;
  categoryImages = [];
  errors = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private categoryService: CategoryService,
    private scriptService: ScriptService,
    public dialog: MatDialog) {
      this.createForm();
    }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.getCategory(id);
    }

    var self = this;
    window.addEventListener("message", function (e) {
      var action = e.data.action;
      var data = e.data.data;
      console.log("action-received: " + action, data);

      if (action == 'media-selected') {
        self.selectedMediaFiles(data.items);
      }
    }, false);
  }
//#region form
createForm() {
  this.categoryForm = this.fb.group({
    categoryName: ['', Validators.required],
    categoryCode: '',
    description: '',
    imageUrl: '',
    isActive: 1,
    seq: 0
  });
}

onSubmit() {
  if (!this.categoryForm.valid) return;
  var model = this.prepareSave();
  this.errors = [];
  this.categoryService.saveCategory(model)
    .subscribe(data => {
      console.log(data);
      if (data.errors.length > 0) {
        this.errors = formUtils.getErrors(data);
      } else {
        this.router.navigate(['/admin/categories']);
      }
    }
    /* error handling */);
  //this.rebuildForm();
}

rebuildForm() {
  this.categoryForm.reset({
    categoryName: this.category.categoryName,
    categoryCode: this.category.categoryCode,
    description: this.category.description,
    imageUrl: this.category.imageUrl,
    isActive: this.category.isActive,
    seq: this.category.seq
  });
}

prepareSave(): any {
  const formModel = this.categoryForm.value;
  console.log(formModel);

  var model: any = {
    categoryId: this.category.categoryId,
    categoryName: formModel.categoryName as string,
    categoryCode: formModel.categoryCode as string,
    description: formModel.description,
    imageUrl: formModel.imageUrl,
    isActive: formModel.isActive,
    seq: formModel.seq
  };

  model.categoryImages = this.categoryImages;

  return model;
}
//#endregion

//#region api

getCategory(id: string): void {
  this.categoryService.getCategory(id)
    .subscribe((data: any) => {
      console.log(data); this.category = data;

      this.categoryImages = data.categoryImages;
      this.rebuildForm();
    });
}

//#endregion

//#region cms media
 openLibrary() {
  var url = environment.cmsEndpoint + '/cms41helper/browse/selectmedia?onSelection=selectedMediaFiles&maxSelect=1';
  var w = window.innerWidth;
  var h = window.innerHeight;
  window['selectedMediaFiles'] = this.selectedMediaFiles;
  window.open(url, 'cms_library', `toolbar=yes,scrollbars=yes,resizable=yes,top=${w * 0.05},left=${h * 0.05},width=${w * 0.9},height=${h * 0.9}`);
}

selectedMediaFiles(items) {

  console.log(items)
  items.forEach(item => {
    this.categoryImages.push({ imageUrl: item.WebPath });
  });
}

removeImage(imageUrl) {
  console.log(imageUrl);
  arrayUtils.remove(this.categoryImages, o => o.imageUrl === imageUrl);
}
//#endregion
}
