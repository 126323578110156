import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwPush } from '@angular/service-worker';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  readonly VAPID_PUBLIC_KEY = "BOmyMTxavdCEE6dd8E_uHdQRu349_15D0Jx2mZSl4MIE-hrAi8VKkHulKOnnvMmBmVR0zGYNe1c8WcQsQbWzIaA";
  pushIsSupported: boolean = 'serviceWorker' in navigator && 'PushManager' in window;

  constructor(
    private route: ActivatedRoute,
    private swPush: SwPush) { }

  ngOnInit() {
    const id = +this.route.snapshot.paramMap.get('id');
    console.log(id);

    this.swPush.messages.subscribe(message => {
      console.log(message);
    });
  }

  subscribeToNotifications() {
    if (this.pushIsSupported) {
      this.swPush.requestSubscription({
        serverPublicKey: this.VAPID_PUBLIC_KEY
      })
        .then(sub => {console.log(sub); console.log(JSON.stringify(sub));} /* this.newsletterService.addPushSubscriber(sub).subscribe() */)
        .catch(err => console.error("Could not subscribe to notifications", err));
    }
  }
}
