import { Component, OnInit, Input, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { CmsItemUtils } from '../../utils/item-utils';
import { Router } from '@angular/router'

@Component({
  selector: 'app-cms-page-tile',
  templateUrl: './cms-page-tile.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CmsPageTileComponent implements OnInit {
  @Input() item: any;
  @Input() index: number;
  @Input() cmsOption: any;
  id: string;
  model: any;

  constructor(public router: Router) { }

  ngOnInit() {
    //console.log(this.item)
    this.model = this.getPages();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.model = this.getPages();
  }

  cssClass() {
    return this.item.cssClass || this.item.anchorName || "";
  }

  payload(key: string) {
    return CmsItemUtils.getPayload(this.item, key);
  }

  pagePayload(page, key) {
    return CmsItemUtils.getPayload(page, key);
  }

  getPages() {
    //console.log('cms-tile-get-pages', this.payload('PageObjects'))
    var pagesSerialized = this.payload('PageObjects');
    if (pagesSerialized) {
      return JSON.parse(pagesSerialized);
    }
    return [];
  }

  handleClick(ev, link) {
    //console.log(ev);
    if (!this.cmsOption.isCmsEdit && link) {
      this.router.navigate([link]);
    }
  }

}
