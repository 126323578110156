import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Dropzone } from './dropzone';
import { DropzoneOption } from './dropzone-option';
import { DropzoneData, DropzoneFile, DropzoneFileStatus } from './dropzone-data';
import { StyleHelper } from '@jaba/j6-ng-utils';
import { AuthService } from '@app/auth/services/auth.service';

@Component({
  selector: 'app-dropzone',
  templateUrl: './dropzone.component.html',
  styles: []
})
export class DropzoneComponent implements OnInit {
  @Input() options: DropzoneOption;
  @ViewChild('dropArea') dropAreaRef: ElementRef;

  dropzone: Dropzone;
  settings: DropzoneOption;
  fileInputId: string;
  data: DropzoneData = { files: [], filterErrors: [] };

  instructionText = '';
  buttonText = '';
  constructor(public styleHelper: StyleHelper, private authService: AuthService) {
    this.fileInputId = 'file-' + Math.floor(Math.random() * 1000000);
  }

  ngOnInit() {
    console.log(this.options);
    // set default values
    this.settings = Object.assign({}, {
      uploadUrl: '/api/file/upload',
      startUploadAfterSelection: true,
      maxNumberOfFiles: 5,
      maxFileSizeInBytes: 20/*MB*/ * 1024 * 1024,
      accept: 'image/*',
      acceptErrorMessage: 'Only image files are allowed.',
      buttonCssClass: 'btn btn-primary',
    }, this.options)

    this.instructionText = this.settings.instructionText ? this.settings.instructionText : (
      this.settings.maxNumberOfFiles > 1 ?
        'Drop files here to add' :
        'Drop a file here to add'
    );

    this.buttonText = this.settings.buttonText ? this.settings.buttonText : (
      this.settings.maxNumberOfFiles > 1 ?
        'or Choose files' :
        'or Choose a file'
    );

    this.dropzone = new Dropzone(this.data, this.dropAreaRef.nativeElement, this.settings, this.authService);
  }

  remove(fileId) {
    this.dropzone.removeFile(fileId);
  }

  getStatusText(file) {
    return this.dropzone.getStatusText(file);
  }

  getStatusCssClass(file) {
    return this.dropzone.getStatusCssClass(file);
  }
}
