import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  private url = environment.apiEndpoint + '/api/FeedbackAdmin';
  constructor(private http: HttpClient) { }
  getFeedbacks(): Observable<any[]> {
    return this.http.get<any[]>(this.url + "/GetList");
  }

  getFeedback(id): Observable<any> {
    return this.http.get<any>(this.url + "/GetFeedback/" + id);
  }

  saveFeedback(feedback: any): Observable<any>  {
    return this.http.post(this.url + "/SaveFeedback", feedback);
  }

  removeFeedback(id): Observable<any>  {
    return this.http.get(this.url + "/RemoveFeedback/" + id);
  }
}
