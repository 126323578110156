import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MaterialModule } from "../material.module";
import { CommonUIModule } from "@app/common-ui/common-ui.module";
import { Routes, RouterModule } from "@angular/router";
//import { AuthRoutingModule } from './auth-routing.module';
// import { StoreModule } from '@ngrx/store';
// //import * as fromAuth from './reducers/auth.reducer';
// import { EffectsModule } from '@ngrx/effects';
// import { AuthEffects } from './effects/auth.effects';

// Firebase services + enviorment module
import { AngularFireModule } from "@angular/fire";
import { AngularFireAuthModule } from "@angular/fire/auth";
//import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from "../../environments/environment";

import { AuthControlService } from "./services/auth-control.service";
import { SignInComponent } from "./components/sign-in/sign-in.component";
import { SignUpComponent } from "./components/sign-up/sign-up.component";
import { LoginStatusComponent } from "./login-status/login-status.component";
import { SignOutComponent } from "./components/sign-out/sign-out.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { VerifyEmailAddressComponent } from "./components/verify-email-address/verify-email-address.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { VerifyEmailServerComponent } from "./components/verify-email-server/verify-email-server.component";
import { WarningVerifyEmailComponent } from "./components/warning-verify-email/warning-verify-email.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    SignInComponent,
    SignUpComponent,
    LoginStatusComponent,
    SignOutComponent,
    ForgotPasswordComponent,
    VerifyEmailAddressComponent,
    ResetPasswordComponent,
    VerifyEmailServerComponent,
    WarningVerifyEmailComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    CommonUIModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    //AuthRoutingModule,
    // //StoreModule.forFeature('auth', fromAuth.reducer),
    // EffectsModule.forFeature([AuthEffects]),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    //AngularFirestoreModule
  ],
  exports: [WarningVerifyEmailComponent],
  providers: [AuthControlService],
})
export class AuthModule {}
