import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import {
  FormArray,
  FormControl,
  FormBuilder,
  FormGroup,
  Validators,
} from "@angular/forms";
import { OrderService } from "../../services/order.service";
import { ScriptService } from "../../../services/script.service";
import { environment } from "../../../../environments/environment";
// import { MatTabChangeEvent } from '@angular/material/tabs';
import * as arrayUtils from "../../../utils/array";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatSnackBar,
  MatSnackBarConfig,
} from "@angular/material";
import { MemberAdminService } from "@app/admin/services/member.service";

@Component({
  selector: "app-member-view",
  templateUrl: "./member-view.component.html",
  styles: [],
})
export class MemberAdminViewComponent implements OnInit {
  model: any;
  id: string;
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar,
    private memberAdminService: MemberAdminService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    if (this.id) {
      this.getMember(this.id);
    }
  }

  getMember(id: string): void {
    this.memberAdminService.getMember(id).subscribe((data: any) => {
      console.log(data);
      this.model = data;
    });
  }

  removeAccount(): void {
    if (!confirm("Delete this account?")) return;
    this.memberAdminService.deleteMember(this.id).subscribe((data: any) => {
      console.log(data);
      this.router.navigate(["/admin/members"]);
    });
  }
}
