import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Router, ActivatedRoute, ParamMap, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { OrderService } from '../../services/order.service';
import { CommonDataService } from '../../services/common-data.service';
import { MetaService } from '../../services/meta.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { LoadingStatus } from '../../common/loading-status';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {
  idSubscriber: Subscription;
  model: any;
  bannerUrl: string;
  constructor(
    private metaService: MetaService,
    public loadingStatus: LoadingStatus,
    private productService: ProductService,
    private orderService: OrderService,
    public store: CommonDataService,
    private route: ActivatedRoute,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.idSubscriber = this.route.params
      .subscribe(
        (params: { Params }) => {
          let id = params['id'];
          // console.log(id);
          this.getProduct(id);
        });
    // const id = this.route.snapshot.paramMap.get('id');
    // console.log(id);
    // this.getProduct(id);
    this.bannerUrl = this.getBanner();
  }

  ngOnDestroy() {
    this.idSubscriber.unsubscribe();
  }

  get cartAction() {
    return this.productService.getAction(this.model);
  }

  getProduct(id): void {
    this.productService.getProduct(id)
      .subscribe(pageData => {
        this.loadingStatus.loaded();
        this.model = pageData.data;
        this.setDefaultOption(this.model);

        this.metaService.addMetaTags({
          pageTitle: this.model.productName,
          payloads: [
            { key: 'Keyword', value: '' },
            { key: 'ImageUrl', value: this.model.imageUrl },
            { key: 'Description', value: this.model.shortDesc }
          ]
        });
        this.store.setPageData(pageData);

        console.log(pageData);
      },
      err => {
        this.loadingStatus.showErrorMessage('Fail to load product information', err.message, err);
      });
  }

  setDefaultOption(product) {
    product.productOptions.forEach(option => {
      if (!option.optionValue && option.items.length > 0) {
        option.optionValue = option.items[0].optionItemName;
      }
    });
  }

  getPrice() {
    if (this.model) {
      var product = this.model;
      var price = product.price;
      product.productOptions.forEach(option => {
        if (option.optionValue && option.items.length > 0) {
          var selected = option.items.filter(x => x.optionItemName == option.optionValue);
          if (selected.length > 0) {
            price += selected[0].additionalAmount;
          }
        }
      });
      return 'AU $' + price;
    }
    return "-";
  }

  addToCart() {
    //console.log(this.model);
    this.submitRequested = true;
    if (this.errors.length > 0) return;

    var product = this.model;
    var cartItem = {
      productId: product.productId,
      quantity: product.quantity,
      productOptions: []
    }
    product.productOptions.forEach(productOption => {
      cartItem.productOptions.push({ optionName: productOption.optionName, optionValue: productOption.optionValue });
    });
    console.log(cartItem);

    this.orderService.addToCart(cartItem)
      .subscribe(data => {
        console.log('added', data);

        if (data && data.results && data.results.cartInfo) {
          this.store.setBadge('cart', data.results.cartInfo.numberOfItems);
        }

        this.model.quantity = 1;
        let config = new MatSnackBarConfig();
        //config.verticalPosition = 'top';
        config.duration = 3000;
        this.snackBar.open('Successfully added', 'Close', config);
      });
  }

  getAdditionalPrice(item) {
    // if (item.additionalAmount != 0) {
    //   return ' (' + (item.additionalAmount > 0? '+ ': '- ') + 'AU $' + item.additionalAmount + ')';
    // }
    var price = this.model.price + item.additionalAmount;
    return ' (' + 'AU $' + price + ')';
  }

  submitRequested = false;
  get errors(): string[] {
    var errors = [];
    if (!this.model.quantity || this.model.quantity <= 0) {
      errors.push('Quantity is required.');
    }
    this.model.productOptions.forEach(productOption => {
      if (!productOption.optionValue) {
        errors.push(`${productOption.optionName} is required.`);
      }
    });
    return errors;
  }

  getBanner() {
    var images = ['herb-collage1920-narrow-01.jpg', 'herb-collage1920-narrow-02.jpg', 'herb-collage1920-narrow-03.jpg'];
    return '/sites/skyezone/media/images/banners/' +  images[Math.floor(Math.random()*images.length)];
  }
}
