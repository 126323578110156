import { format, addDays, getISODay } from 'date-fns';
import * as moneyUtils from '@jaba/j6-utils/src/money';
import * as formUtils from '@jaba/j6-utils/src/form';
import * as routerUtils from '@jaba/j6-utils/src/router';
import * as uiUtils from '@jaba/j6-utils/src/ui';


export { FormHelper } from '@jaba/j6-ng-utils';
export { StyleHelper } from '@jaba/j6-ng-utils';

export { formUtils, moneyUtils, routerUtils, uiUtils };

export const formatDate = (date) => {
  //console.log(date)
  return format(date, 'D MMM YYYY')
}

export const formatDateAU = (date) => {
  //console.log(date)
  return format(date, 'DD/MM/YYYY')
}

export function formatMoney(number, forcingFraction = null) {
  return 'AU ' + moneyUtils.formatMoney(number, forcingFraction);
}


export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function getPopupWidth() {
  const w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  if (w > 1000)
    return '900px';
  else if (w < 600)
     return '95%';
  else
    return '80%';

}

//#region date calculation
export function getMinDate(purchaseProduction) {
  //console.log(firstAvailable(purchaseProduction))
  return getClosestDayOfWeek('Mon', firstAvailable(purchaseProduction));
}

export function getMinSpotDate(purchaseProduction) {
  return firstAvailable(purchaseProduction);
}

export function firstAvailable(purchaseProduction) {
  var date = new Date();
  var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  if (date.getHours() >= 12) {
    //console.log('firstAvailable1', purchaseProduction, date.getHours() )
    return purchaseProduction? getFirstWeekday(addDays(today, 4)) : getFirstWeekday(addDays(today, 2));
  } else {
    //console.log('firstAvailable2', purchaseProduction, date.getHours() )
    return purchaseProduction? getFirstWeekday(addDays(today, 3)) : getFirstWeekday(addDays(today, 1));
  }
}

export function getFirstWeekday(date) {
  //console.log('getFirstWeekday', date)
  const day = getISODay(date);
  if (day == 7) return addDays(date, 1);
  if (day == 6) return addDays(date, 2);
  return date;
}

export function toDateString(date) {
  return format(date, 'DD/MM/YYYY');
}

//utils.getClosestDayOfWeek('Mon')
export function getClosestDayOfWeek(dayOfWeek, fromDate = new Date()) {
  // follow the getISODay format (7 for Sunday, 1 for Monday)
  const dayOfWeekMap = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thur: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  };

  // -7 means last week
  // dayOfWeekMap[dayOfWeek] get the ISODay for the desired dayOfWeek

  // e.g. If today is Sunday, getISODay(fromDate) will returns 7
  // if the day we want to find is Thursday(4), apart from subtracting one week(-7),
  // we also need to account for the days between Sunday(7) and Thursday(4)
  // Hence we need to also subtract (getISODay(fromDate) - dayOfWeekMap[dayOfWeek])
  const offsetDays = 7 - (getISODay(fromDate) - dayOfWeekMap[dayOfWeek]);

  return addDays(fromDate, offsetDays);
}
//#endregion

