import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cms-banner-panel',
  templateUrl: './cms-banner-panel.component.html',
  styleUrls: ['./cms-banner-panel.component.scss']
})
export class CmsBannerPanelComponent implements OnInit {
  @Input() item: any;
  @Input() index: number;
  @Input() cmsOption: any;
  id: string;
  constructor() { }

  ngOnInit() {
  }

  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    fade: true
  };

  afterChange(e) {
    //console.log('afterChange');
  }
}
