import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AuthControlService } from "@app/auth/services/auth-control.service";
import { AUTH_URLS } from "@app/auth/auth-urls";
import { LoadingStatus } from "@app/common-ui/loading-status";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonErrorStateMatcher } from "@app/common/error-status-matcher";
import { hasError, markFormGroupTouched } from "@app/utils/ng-form";
import { ContactService } from "@app/services/contact.service";
import { convertToUTC } from "@app/utils/datetime";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styles: [],
})
export class SignUpComponent implements OnInit {
  @ViewChild("email") emailRef: ElementRef;
  @ViewChild("password") passwordRef: ElementRef;
  @ViewChild("agree") agreeRef: ElementRef;
  AUTH_URLS = AUTH_URLS;
  submitting = false;
  returnUrl = "";
  model: any;
  formGroup: FormGroup;
  matcher = new CommonErrorStateMatcher();
  hasError = hasError;

  public loadingStatus = new LoadingStatus();

  constructor(
    private fb: FormBuilder,
    public authControlService: AuthControlService,
    public contactService: ContactService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loadFormData();
    this.route.queryParamMap.subscribe((params) => {
      this.returnUrl = params.get("returnUrl");
    });
  }

  loadFormData() {
    this.contactService.getSubscriptionFormData().subscribe((x) => {
      console.log(x);
      this.model = x;

      this.createForm();
      this.loadingStatus.loaded();
      // this.formGroup.reset(x);
    });
  }

  createForm() {
    this.formGroup = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      state: ["", Validators.required],
      gender: [null, Validators.required],
      dob: ["", Validators.required],
      productCategories: [null, [Validators.required]],
      skinType: [null, [Validators.required]],
      password: ["", Validators.required],
    });
  }

  // submit(ev) {
  //   ev.preventDefault();
  //   this.signUp();
  // }

  onSubmit() {
    markFormGroupTouched(this.formGroup);
    if (this.formGroup.invalid) return;

    var opt = this.formGroup.value;
    console.log(opt);

    opt.dob = convertToUTC(opt.dob);
    let agree = this.agreeRef.nativeElement.checked;
    //console.log(email, password, agree);

    if (!agree) {
      this.authControlService.displayError(
        "Please agree the Terms & Conditions to continue."
      );
      return;
    }

    this.loadingStatus.loading();
    this.submitting = true;
    this.authControlService
      .signUp(opt.email, opt.password, this.returnUrl, opt)
      .then((x) => {
        this.submitting = false;
        this.loadingStatus.loaded();
      });
  }

  googleAuth() {
    let agree = this.agreeRef.nativeElement.checked;
    //console.log(email, password, agree);

    if (!agree) {
      this.authControlService.displayError(
        "Please agree the Terms & Conditions to continue."
      );
      return;
    }
    this.loadingStatus.loading();
    this.authControlService.googleAuth().then((x) => {
      this.loadingStatus.loaded();
    });
  }
}
