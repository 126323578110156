import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSort, MatTableDataSource } from "@angular/material";
import { OrderService } from "../../services/order.service";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
})
export class OrdersComponent implements OnInit {
  displayedColumns: string[] = [
    "orderDate",
    "orderNo",
    "totalAmount",
    "firstName",
    "surname",
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  orders = [];
  totalCnt = -1;

  constructor(private orderService: OrderService) {}

  ngOnInit() {
    this.getOrders(this.getDefaultFilter());
    this.dataSource.sort = this.sort;
  }

  getOrders(filter): void {
    this.orderService.getOrders(filter).subscribe((data) => {
      //console.log(data);
      this.orders = data;
      this.dataSource.data = data;
      this.totalCnt = data ? data.length : 0;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  totalCountHtml() {
    return this.totalCnt >= 0 ? "(" + this.totalCnt + ")" : "Loading...";
  }

  filterOrder(e) {
    console.log(e);
    window.localStorage.setItem("ordersFilterDate", e.value);
    this.getOrders({ startFrom: e.value });
  }

  getDefaultFilter() {
    return {
      startFrom: window.localStorage.getItem("ordersFilterDate") || null,
    };
  }

  startFrom() {
    return window.localStorage.getItem("ordersFilterDate") || null;
  }
}
