export const getErrors = (result) => {
  let errors = [];
  if (result && result.errors && result.errors.length > 0) {
    result.errors.forEach(err => {
      errors.push(err.errorMessage);
    });
  }
  return errors;
}

export const getErrorMessage = (result) => {
  let errors = getErrors(result);
  return errors.join(" ");
}

// // $utils.form.createFormData()
// export const getFormDataJson = (form) => {
//   var jsonData = {}
//   var fields = $(form).find('input,textarea,select,hidden')
//     .not('[type=hidden][name^=__]')
//     .serializeArray()
//   $.each(fields, function (inx, fld) {
//     jsonData[fld.name] = fld.value
//   })
//   return jsonData
// }

// // $utils.form.createFormData()
// export const createFormData = (form) => {
//   var formData = new FormData()

//   var fields = $(form).find('input,textarea,select,hidden')
//     .not('[type=hidden][name^=__]')
//     .serializeArray()
//   $.each(fields, function (inx, fld) {
//     formData.append(fld.name, fld.value)
//   })
//   return formData
// }
