export const environment = {
  siteCode: "Skyezone",
  production: true,
  ver: "1.0.17",
  updateDate: "4 May 2022",
  freeShipping: 100,
  shippingFee: 12,
  expressFee: 14,
  apiEndpoint: "",
  cmsEndpoint: "https://cms.skyezone.net",
  clientApiKey:
    "ujkWzRdDtWqcSCoMLLM82Kirxj4p4bkKYWVchiij04sypT3ftcRBlldFx0Z1qCozDpcWSU0zJ8nS8hrZnLxp9D1zjJ3CgDj9uEhD2OIxSuI4GMQanvEMMBgYe6vKb8Uy2f/On86WkUb/Qo0ACoEaYQNMwhHcrpMdwowSq0aoH6gUfKkAMfPE3hicYYmmhlPzLlImgdu/W3YpkOrcKUheGWttgA6GNUS6pp93X+2cthCPZcDTTageHYNC36krQZPZHTCuvmBjSJRCP6D7xWWrbB/BnTsy/oIsF7rF1bxLE/frL82hWICy6RQbEgjUbsBd6O2ux2r+DRmtydIbeLI1Pw==",

  firebase: {
    apiKey: "AIzaSyArQi8qAn8zcm7hShUncUjVkq7kFbyFzv8",
    authDomain: "skyezone-d8804.firebaseapp.com",
    projectId: "skyezone-d8804",
    storageBucket: "skyezone-d8804.appspot.com",
    messagingSenderId: "448942498573",
    appId: "1:448942498573:web:0fd268b2d044f41dfec644",
  },
};
