import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { AuthControlService } from "@app/auth/services/auth-control.service";
import { AUTH_URLS } from "@app/auth/auth-urls";
import { LoadingStatus } from "@app/common-ui/loading-status";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-sign-in",
  templateUrl: "./sign-in.component.html",
  styles: [],
})
export class SignInComponent implements OnInit {
  @ViewChild("email") emailRef: ElementRef;
  @ViewChild("password") passwordRef: ElementRef;
  //@ViewChild('remember') rememberRef: ElementRef;
  AUTH_URLS = AUTH_URLS;
  submitting = false;
  returnUrl = "";

  public loadingStatus = new LoadingStatus();

  constructor(
    public authControlService: AuthControlService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.loadingStatus.loaded();
    this.route.queryParamMap.subscribe((params) => {
      this.returnUrl = params.get("returnUrl");
    });
  }

  submit(ev) {
    ev.preventDefault();
    this.signIn();
  }

  signIn() {
    let email = this.emailRef.nativeElement.value;
    let password = this.passwordRef.nativeElement.value;
    //let remember = this.rememberRef.nativeElement.checked;

    //console.log(email, password);
    this.loadingStatus.loading();
    this.submitting = true;
    this.authControlService
      .signIn(email, password, this.returnUrl)
      .then((x) => {
        this.loadingStatus.loaded();
        this.submitting = false;
      });
  }

  googleAuth() {
    this.loadingStatus.loading();
    this.authControlService.googleAuth().then((x) => {
      this.loadingStatus.loaded();
    });
  }
}
