import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

// import { Category } from '../entities/category';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class PageService {
  private url = environment.apiEndpoint + '/api/Cms/';
  constructor(private http: HttpClient) { }
  getPage(id: string, cmsViewMode:string): Observable<any> {
    return this.http.get<any>(this.url + 'GetPage/' + id + '?cmsViewMode=' + cmsViewMode);
  }

  getPublishedPage(path: string): Observable<any> {
    return this.http.get<any>(this.url + 'GetPublishedPage?path=' + encodeURI(path));
  }
}
