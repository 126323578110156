import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ContactService {
  private endpoint = environment.apiEndpoint;
  constructor(private http: HttpClient) {}

  contact(info: any): Observable<any> {
    console.log(info);
    return this.http.post(this.endpoint + "/api/Contact/Send", info);
  }

  getSubscriptionFormData(): Observable<any[]> {
    return this.http.get<any[]>(
      this.endpoint + "/api/Contact/getSubscriptionFormData"
    );
  }

  subscribeNewsletter(info: any): Observable<any> {
    console.log(info);
    return this.http.post(this.endpoint + "/api/Contact/subscribe", info);
  }
}
