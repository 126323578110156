import { Routes, RouterModule } from "@angular/router";
import { SignInComponent } from "./components/sign-in/sign-in.component";
import { SignUpComponent } from "./components/sign-up/sign-up.component";
import { SignOutComponent } from "./components/sign-out/sign-out.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { VerifyEmailAddressComponent } from "./components/verify-email-address/verify-email-address.component";
import { LoginStatusComponent } from "./login-status/login-status.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { VerifyEmailServerComponent } from "./components/verify-email-server/verify-email-server.component";

export const AUTH_ROUTES: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "signup", component: SignUpComponent },
  { path: "signin", component: SignInComponent },
  { path: "login", component: SignInComponent },
  { path: "sign-out", component: SignOutComponent },
  { path: "logout", component: SignOutComponent },
  { path: "forgot-password", component: ForgotPasswordComponent },
  { path: "reset-password", component: ResetPasswordComponent },
  { path: "verify-email-address", component: VerifyEmailAddressComponent },
  { path: "verify-email", component: VerifyEmailServerComponent },
  { path: "status", component: LoginStatusComponent },
];
