import { Component, OnInit, ViewChild } from "@angular/core";
import { MatSort, MatTableDataSource } from "@angular/material";
import { MemberAdminService } from "@app/admin/services/member.service";

@Component({
  selector: "app-members",
  templateUrl: "./members.component.html",
  styleUrls: ["./members.component.scss"],
})
export class MembersComponent implements OnInit {
  displayedColumns: string[] = [
    "userId",
    // "email",
    "memberTypeText",
    "profileStatusText",
    "emailVerified",
    "dateCreated",
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort) sort: MatSort;

  orders = [];
  totalCnt = -1;

  constructor(private memberService: MemberAdminService) {}

  ngOnInit() {
    this.getOrders();
    this.dataSource.sort = this.sort;
  }

  getOrders(): void {
    this.memberService.getMembers().subscribe((data) => {
      //console.log(data);
      this.orders = data;
      this.dataSource.data = data;
      this.totalCnt = data ? data.length : 0;
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  totalCountHtml() {
    return this.totalCnt >= 0 ? "(" + this.totalCnt + ")" : "Loading...";
  }
}
