import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CategoryService } from '../../services/category.service';
import { ScriptService } from '../../../services/script.service';
import { environment } from '../../../../environments/environment';
// import { MatTabChangeEvent } from '@angular/material/tabs';
import * as arrayUtils from '../../../utils/array';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-product-order-in-category',
  templateUrl: './product-order-in-category.component.html',
  styles: []
})
export class ProductOrderInCategoryComponent implements OnInit {
  category: any = { categoryId: null, categoryName: '', products: [] };
  categoryForm: FormGroup;
  categoryImages = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private categoryService: CategoryService,
    private scriptService: ScriptService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.getCategory(id);
    }
  }

  //#region form
  save() {
    this.categoryService.saveProductSort(this.category)
      .subscribe(data => {
        this.router.navigate(['/admin/categories']);
      }
    /* error handling */);
    //this.rebuildForm();
  }

  //#endregion

  //#region api

  getCategory(id: string): void {
    this.categoryService.getCategory(id)
      .subscribe((data: any) => {
        console.log(data); this.category = data;
      });
  }

  //#endregion

}
