import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '../../services/product.service';
import { ScriptService } from '../../../services/script.service';
import { environment } from '../../../../environments/environment';
// import { MatTabChangeEvent } from '@angular/material/tabs';
import * as arrayUtils from '../../../utils/array';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ProductEditOptionComponent } from '../product-edit-option/product-edit-option.component';
import { ProductEditFeedbackComponent } from '../product-edit-feedback/product-edit-feedback.component';

@Component({
  selector: 'app-formula-edit',
  templateUrl: './formula-edit.component.html',
  styles: []
})
export class FormulaEditComponent implements OnInit {
  product: any = { ProductId: null, ProductStatus: 0 };
  productForm: FormGroup;
  productImages = [];
  productOptions = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private productService: ProductService,
    private scriptService: ScriptService,
    public dialog: MatDialog) {
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.getProduct(id);
    }
  }

  //#region api

  getProduct(id: string): void {
    this.productService.getProduct(id)
      .subscribe((data: any) => {
        console.log(data); this.product = data;
      });
  }

  saveFormula() {
    var model: any = {
      productId: this.product.productId,
      formula: this.product.formula
    };

    this.productService.saveProductFormula(model)
      .subscribe(data => {
        let config = new MatSnackBarConfig();
        //config.verticalPosition = 'top';
        config.duration = 3000;
        this.snackBar.open('Product Formula - Successfully saved.', 'Close', config);
        //this.router.navigate(['/admin/products']);
      }
      /* error handling */);
    //this.rebuildForm();
  }


  //#endregion

}
