import { Component, OnInit, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { LoadingStatus } from '../loading-status';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styles: [],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingComponent implements OnInit {
  @Input() loadingStatus: LoadingStatus;
  @Input() pageLoading: boolean;
  constructor() {
    this.pageLoading = false;
   }

  ngOnInit() {
  }

}
