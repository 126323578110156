import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private endpoint = environment.apiEndpoint;
  constructor(private http: HttpClient) { }

  search(option): Observable<any> {
    return this.http.get<any>(this.endpoint + '/api/Search/Search', {params: option});
  }
}
