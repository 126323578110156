import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'
import { FormArray, FormControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '../../services/product.service';
import { ScriptService } from '../../../services/script.service';
import { environment } from '../../../../environments/environment';
// import { MatTabChangeEvent } from '@angular/material/tabs';
import * as arrayUtils from '../../../utils/array';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ProductEditOptionComponent } from '../product-edit-option/product-edit-option.component';
import { ProductEditFeedbackComponent } from '../product-edit-feedback/product-edit-feedback.component';

declare var CKEDITOR: any;

@Component({
  selector: 'app-product-edit',
  templateUrl: './product-edit.component.html',
  styleUrls: ['./product-edit.component.scss']
})
export class ProductEditComponent implements OnInit {
  product: any = { productId: null, ProductStatus: 0 };
  productForm: FormGroup;
  productImages = [];
  productOptions = [];

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    public snackBar: MatSnackBar,
    private productService: ProductService,
    private scriptService: ScriptService,
    public dialog: MatDialog) {
    this.createForm();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.getProduct(id);
    }

    const ver = 1;
    this.scriptService.loadJs(
      '/assets/cms/ckeditor/ckeditor.js?v=' + ver
    ).then(data => {
      //console.log('script loaded ', data);
      this.initHtmlEditor();
    }).catch(error => console.log(error));

    var self = this;
    window.addEventListener("message", function (e) {

      var action = e.data.action;
      var data = e.data.data;

      console.log("action-received: " + action, data);

      if (action == 'media-selected') {
        self.selectedMediaFiles(data.items);
      }
    }, false);
  }

  addProductOption() {
    const productOptionsArray = this.productForm.get('productOptions') as FormArray;
    const dialogRef = this.dialog.open(ProductEditOptionComponent, {
      //width: '250px',
      data: { isEdit: false, optionName: '', optionCode: '', Items: [] }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        productOptionsArray.push(this.fb.group({
          optionName: [result.optionName, Validators.required],
          optionCode: [''],
          items: this.fb.array([])
        }));
      }
    });
  }
  editOption(inx) {
    const productOptionsArray = this.productForm.get('productOptions') as FormArray;
    var productOptionGroup = productOptionsArray.at(inx);

    const dialogRef = this.dialog.open(ProductEditOptionComponent, {
      //width: '250px',
      data: { isEdit: true, optionName: productOptionGroup.value.optionName, optionCode: '', Items: [] }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      if (result) {
        productOptionGroup.patchValue({ optionName: result.optionName });
        //console.log(productOptionGroup, { optionName: result.optionName })
      }
    });
  }

  //#region form
  createForm() {
    this.productForm = this.fb.group({
      productName: '',
      productCode: '',
      price: 0,
      priceText: '',
      shortDesc: '',
      description: '',
      productStatus: 0,
      uses: '',
      productCategories: this.fb.array([]),
      productOptions: this.fb.array([]),
      contents: this.fb.array([])
    });
  }

  onSubmit() {
    var model = this.prepareSave();
    this.productService.saveProduct(model)
      .subscribe(data => {
        this.router.navigate(['/admin/products']);
      }
      /* error handling */);
    //this.rebuildForm();
  }

  delete() {
    if (!confirm('Remove permanently?')) return;
    this.productService.deleteProduct(this.product.productId)
      .subscribe(data => {
        this.router.navigate(['/admin/products']);
      });
  }

  rebuildForm() {
    this.productForm.reset({
      productName: this.product.productName,
      productCode: this.product.productCode,
      price: this.product.price,
      priceText: this.product.priceText,
      shortDesc: this.product.shortDesc,
      description: this.product.description,
      productStatus: this.product.productStatus,
      uses: this.product.uses,
      productCategories: null,
      productOptions: null,
      contents: null
    });

    const formArray = this.productForm.get('productCategories') as FormArray;
    this.product.productCategories.forEach(x => formArray.push(new FormControl(x.selected)));

    // product options
    const productOptionsArray = this.productForm.get('productOptions') as FormArray;
    this.product.productOptions.forEach(x => {
      var options = this.fb.array([]);
      console.log(x);
      x.items.forEach(item => options.push(this.fb.group({
        optionItemName: [item.optionItemName, Validators.required],
        additionalAmount: [item.additionalAmount, Validators.required]
      })));
      productOptionsArray.push(this.fb.group({
        optionName: [x.optionName, Validators.required],
        optionCode: [x.optionCode],
        items: options
      }));
    });

    // product contents
    const contentsArray = this.productForm.get('contents') as FormArray;
    this.product.contents.forEach(x => {
      contentsArray.push(this.fb.group({
        content: [x.content],
        benefit: [x.benefit]
      }));
    });
  }

  prepareSave(): any {
    CKEDITOR.instances.Description.updateElement();
    this.productForm.controls['description'].setValue(CKEDITOR.instances.Description.getData())
    const formModel = this.productForm.value;
    console.log(formModel);

    // // deep copy of form model lairs
    // const secretLairsDeepCopy: Address[] = formModel.secretLairs.map(
    //   (address: Address) => Object.assign({}, address)
    // );

    var model: any = {
      productId: this.product.productId,
      productName: formModel.productName as string,
      productCode: formModel.productCode as string,
      price: formModel.price,
      priceText: formModel.priceText,
      shortDesc: formModel.shortDesc,
      productStatus: formModel.productStatus,
      description: formModel.description as string,
      uses: formModel.uses
    };

    model.productCategories = formModel.productCategories.map((selected, i) => {
      return {
        categoryId: this.product.productCategories[i].categoryId,
        selected: selected
      }
    });

    model.productImages = this.productImages;

    model.productOptions = formModel.productOptions;
    model.contents = formModel.contents;

    return model;
  }

  addOptionItem(inx) {
    const productOptionsArray = this.productForm.get('productOptions') as FormArray;
    var productOptionGroup = productOptionsArray.at(inx);
    var productOptionItemsArray = productOptionGroup.get('items') as FormArray;
    productOptionItemsArray.push(this.fb.group({
      optionItemName: ['', Validators.required],
      additionalAmount: ['0', Validators.required]
    }));
  }

  removeOptionItem(inx, jnx) {
    if (!confirm('Remove this?')) return;
    const productOptionsArray = this.productForm.get('productOptions') as FormArray;
    var productOptionGroup = productOptionsArray.at(inx);
    var productOptionItemsArray = productOptionGroup.get('items') as FormArray;
    productOptionItemsArray.removeAt(jnx);
  }

  removeOption(inx) {
    if (!confirm('Remove this option?')) return;
    const productOptionsArray = this.productForm.get('productOptions') as FormArray;
    productOptionsArray.removeAt(inx);
  }

  addContent() {
    const contentsArray = this.productForm.get('contents') as FormArray;
    contentsArray.push(this.fb.group({
      content: '',
      benefit: ''
    }));
  }


  removeContent(inx) {
    if (!confirm('Remove this content?')) return;
    const contentsArray = this.productForm.get('contents') as FormArray;
    contentsArray.removeAt(inx);
  }

  //#endregion

  //#region api

  getProduct(id: string): void {
    this.productService.getProduct(id)
      .subscribe((data: any) => {
        console.log(data); this.product = data;
        this.productImages = data.productImages;
        this.productOptions = data.productOptions;
        this.rebuildForm();

        if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances["Description"]) {
          // already ckeditor instanciated.
          CKEDITOR.instances["Description"].setData(this.product.description);
        }
      });
  }

  saveFormula() {
    var model: any = {
      productId: this.product.productId,
      formula: this.product.formula
    };

    this.productService.saveProductFormula(model)
      .subscribe(data => {
        let config = new MatSnackBarConfig();
        //config.verticalPosition = 'top';
        config.duration = 3000;

        this.snackBar.open('Product Formula - Successfully saved.', 'Close', config);
        //this.router.navigate(['/admin/products']);
      }
      /* error handling */);
    //this.rebuildForm();
  }


  //#endregion

  //#region cms media
  openLibrary() {
    var url = environment.cmsEndpoint + '/cms41helper/browse/selectmedia?onSelection=selectedMediaFiles&maxSelect=100';
    var w = window.innerWidth;
    var h = window.innerHeight;
    window['selectedMediaFiles'] = this.selectedMediaFiles;
    window.open(url, 'cms_library', `toolbar=yes,scrollbars=yes,resizable=yes,top=${w * 0.05},left=${h * 0.05},width=${w * 0.9},height=${h * 0.9}`);
  }

  selectedMediaFiles(items) {

    console.log(items)
    items.forEach(item => {
      this.productImages.push({ imageUrl: item.WebPath });
    });
  }

  removeImage(imageUrl) {
    console.log(imageUrl);
    arrayUtils.remove(this.productImages, o => o.imageUrl === imageUrl);
  }

  initHtmlEditor() {
    CKEDITOR.replace('Description',
      {
        startupFocus: true,
        filebrowserBrowseUrl: environment.cmsEndpoint + '/cms41helper/browse/selectpageormedia',//'/cms/browse',//'/cms/resource/selectfileckeditor',
        filebrowserImageBrowseUrl: environment.cmsEndpoint + '/cms41helper/browse/selectmedia',//'/cms/resource/selectfileckeditor?type=Images',
        extraPlugins: 'sourcedialog,youtubejaba,lineutils,widget,image2,iframedialog',
        toolbar: [
          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'], items: ['Bold', 'Italic', '-', 'RemoveFormat'] },
          { name: 'paragraph', groups: ['list', 'align'], items: ['NumberedList', 'BulletedList', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'] },
          { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
          //{ name: 'insert', items: ['Image', 'Youtube', 'Table'] },
          '/',
          { name: 'clipboard', groups: ['clipboard', 'undo'], items: ['PasteText', 'PasteFromWord'] },
          { name: 'styles', items: ['Styles', 'Format'] },
          { name: 'colors', items: ['TextColor', 'BGColor'] },
          { name: 'document', groups: ['mode'], items: ['Sourcedialog'] }
          //,        { name: 'tools', items: ['Maximize', 'ShowBlocks', 'iframe'] }
        ]
        , height: '250px'
        //, stylesSet: 'default:/core/ckeditor/_sites/_blog/styles.js'
        , allowedContent: true
        //, removeFormatAttributes:''
        //, extraAllowedContent: 'style;*[id,rel](*){*};blockquote[id,rel](*){*};iframe[*];a[*];i[*]'
      });

    CKEDITOR.dtd.$removeEmpty['i'] = false;

  }
  //#endregion

  //#region Feedback

  addFeedback() {
    const dialogRef = this.dialog.open(ProductEditFeedbackComponent, {
      //width: '250px',
      data: { isEdit: false, feedbackContent: '', name: '', feedbackId: null, feedbackDate: '', source: '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);
      if (result)
        this.saveFeeback(result, null);
    });
  }
  editFeedback(inx, feedback) {

    const dialogRef = this.dialog.open(ProductEditFeedbackComponent, {
      //width: '250px',
      data: { isEdit: true, feedbackContent: feedback.feedbackContent, name: feedback.name, feedbackId: feedback.feedbackId, feedbackDate: feedback.feedbackDate, source: feedback.source }
    });

    dialogRef.afterClosed().subscribe(result => {
      //console.log('The dialog was closed', result);
      if (result)
        this.saveFeeback(result, inx);
    });
  }

  removeFeedback(inx, feedback) {
    if (!confirm('Remove this feedback?')) return;
    this.productService.removeProductFeedback(feedback)
      .subscribe(data => {
        arrayUtils.removeAt(this.product.feedbacks, inx);
        this.sortFeedback(this.product.feedbacks);

        let config = new MatSnackBarConfig();
        //config.verticalPosition = 'top';
        config.duration = 3000;
        this.snackBar.open('Feedback - Successfully removed.', 'Close', config);
      }
      /* error handling */);
  }

  saveFeeback(feedback, inx) {
    feedback.productId = this.product.productId;
    this.productService.saveProductFeedback(feedback)
      .subscribe(data => {
        console.log(data, inx);
        if (inx != null) {
          this.product.feedbacks[inx] = feedback;
          console.log(this.product.feedbacks[inx]);
        } else {
          feedback.feedbackId = data;
          this.product.feedbacks.push(feedback);
        }

        this.sortFeedback(this.product.feedbacks);

        let config = new MatSnackBarConfig();
        //config.verticalPosition = 'top';
        config.duration = 3000;
        this.snackBar.open('Feedback - Successfully saved.', 'Close', config);
      }
      /* error handling */);
  }

  sortFeedback(feedbacks) {
    feedbacks.sort(function (a, b) {
      var compared = arrayUtils.sortCompare(a.feedbackDate, b.feedbackDate, false);
      if (compared == 0)
        return arrayUtils.sortCompare(a.feedbackId, b.feedbackId, false);
      else
        return compared;
    });
  }
  //#endregion
}
