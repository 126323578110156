export function isBlank(str) {
  return (!str || /^\s*$/.test(str))
}

export function isEmpty(str) {
  return (!str || str.length === 0)
}

export function toTitle(str) {
  if (!str) return '';
  return str.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')
}

export function toHtml(str) {
  return str.replace(/[\n\r]/g, '<br/>')
}

// Shorten a string to less than maxLen characters without truncating words.
export function truncateWords(str, maxLen, separator = ' ', ellipsis = '...') {
  if (str.length <= maxLen) return str
  var pos = str.lastIndexOf(separator, maxLen)
  return str.substr(0, pos) + ellipsis
}

export function formatMoney(number) {
  var formatter = new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    // minimumFractionDigits: 2,

  });


  return 'AU ' + formatter.format(number);
}
