import { environment } from "../../../environments/environment";
import { Injectable, NgZone } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";

import { AngularFireAuth } from "@angular/fire/auth";
//import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { CommonUIService } from "@app/common-ui/services/common-ui.service";
import { User } from "@app/core/entities/user";
import { AUTH_URLS } from "../auth-urls";
import * as formUtils from "@jaba/j6-utils/src/form";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  // Authentication navigation
  private userData;
  private refreshTimeout;

  constructor(
    private http: HttpClient,
    //public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    private commonUIService: CommonUIService,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    // Saving user data in localstorage when logged in and setting up null when logged out
    this.afAuth.authState.subscribe((user) => {
      // console.log("auth-changed", user);
      if (user) {
        this.setUser(user, false);
      } else {
        localStorage.setItem("user", null);
      }
    });
  }

  //#region set user
  async setUser(user, forceRefresh) {
    const tokenResult = await user.getIdTokenResult(/* forceRefresh */ false);
    //console.log(tokenResult);
    this.userData = user;
    let userJson = this.wrapUser(user, tokenResult);
    localStorage.setItem("user", JSON.stringify(userJson));

    const ajaxResult = await this.getUserProfile(user.uid).toPromise();
    const errors = formUtils.getErrors(ajaxResult);
    if (errors.length > 0) throw errors.join(" ");
    //console.log(ajaxResult, userJson);
    userJson.profileCheched = true;
    if (
      ajaxResult.results &&
      ajaxResult.results.userInfo &&
      ajaxResult.results.userInfo.roles
    )
      userJson.roles = ajaxResult.results.userInfo.roles;

    localStorage.setItem("user", JSON.stringify(userJson));
    JSON.parse(localStorage.getItem("user"));
    return userJson;
  }

  wrapUser(user: any, tokenResult: any): User {
    return {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoUrl: user.photoURL,
      emailVerified: user.emailVerified,
      profileCheched: false,
      token: tokenResult ? tokenResult.token : null,
      roles: [],
      //refreshToken: user.refreshToken,
      expireAt: tokenResult ? tokenResult.expirationTime : null,
    };
  }
  //#endregion

  //#region query
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem("user"));
    //return (user !== null && user.emailVerified !== false) ? true : false;
    return user !== null;
  }

  get currentUser(): User {
    const user = JSON.parse(localStorage.getItem("user"));
    return user;
  }

  get profileCheched(): boolean {
    return (this.currentUser || { profileCheched: false }).profileCheched;
  }

  get isAdmin(): boolean {
    return this.checkUserRole(this.currentUser, "admin");
  }

  // get isBuyerUser(): boolean {
  //   return this.checkUserRole(this.currentUser, 'buyer');
  // }

  hasRole(role: string): boolean {
    return this.checkUserRole(this.currentUser, role);
  }

  checkUserRole(user, role): boolean {
    if (user && user.roles) {
      return !!user.roles.find(
        (x) => (x || "").toLowerCase() == role.toLowerCase()
      );
    }
    return false;
  }
  //#endregion

  async extendSession() {
    if (this.currentUser && this.currentUser.expireAt) {
      const user = this.currentUser || this.afAuth.auth.currentUser;
      if (user) {
        let now = new Date();
        //console.log(this.currentUser, ((+new Date(this.currentUser.expireAt)) - +now) / 60 / 1000)
        if (
          +new Date(this.currentUser.expireAt) - +now <
          11 /*min*/ * 60 * 1000
        ) {
          await this.setUser(this.userData, true);
        }
      }
    }
  }

  //#region update to asp.net core
  getUserProfile(uid): Observable<any> {
    return this.http.get<any>("/api/profile/GetUserProfile/" + uid);
  }

  updateProfile(user, verifyEmail): Observable<any> {
    return this.http.post<any>(
      "/api/profile/UpdateUserProfile",
      Object.assign({ verifyEmail: verifyEmail }, user)
    );
  }

  sendVerificationMail(): Observable<any> {
    return this.http.post<any>(
      "/api/profile/SendVerificationMail",
      this.currentUser
    );
  }

  verificationMail(model): Observable<any> {
    return this.http.post<any>("/api/profile/VerificationMail", model);
  }

  sendPasswordResetEmail(user): Observable<any> {
    return this.http.post<any>("/api/profile/SendPasswordResetEmail", user);
  }

  resetPassword(model): Observable<any> {
    return this.http.post<any>("/api/profile/ResetPassword", model);
  }

  cancelMasquerade(): Observable<any> {
    return this.http.post<any>("/api/admin/cancelmasquerade", {});
  }
  //#endregion
}
