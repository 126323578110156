import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  NgZone,
} from "@angular/core";
import { OrderService } from "../../services/order.service";
import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { LoadingStatus } from "../../common/loading-status";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import * as PlaceUtils from "../../utils/google-places";
import * as arrayUtils from "../../utils/array";
import * as ngFormUtils from "../../utils/ng-form";
import * as moneyUtils from "../../utils/money";
import { AddressEditorComponent } from "../address-editor/address-editor.component";
import { CommonDataService } from "../../services/common-data.service";
import { CommonErrorStateMatcher } from "../../common/error-status-matcher";
import * as addressForm from "./address-form";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from "../../../environments/environment";
import { CookieService } from "ngx-cookie-service";

import * as utils from "@app/shared/utils";
import { SelectAddressPopupComponent } from "../select-address-popup/select-address-popup.component";
import { AuthService } from "@app/auth/services/auth.service";
declare var google;
declare var eCrypt;

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styles: [],
})
export class CartComponent implements OnInit {
  cart: any;
  itemTotalAmount = 0;
  shippingFee = 0;
  isFreeShipping = false;
  standardShippingFee = 0;
  expressShippingFee = 0;
  loaded = false;
  errors = [];

  formatMoney = moneyUtils.formatMoney;
  matcher = new CommonErrorStateMatcher();
  @ViewChild("shippingAddressRef") shippingAddressRef: ElementRef;
  @ViewChild("billingAddressRef") billingAddressRef: ElementRef;

  qtys: number[];
  constructor(
    private ngZone: NgZone,
    private cd: ChangeDetectorRef,
    public authService: AuthService,
    public loadingStatus: LoadingStatus,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private cookieService: CookieService,
    public router: Router,
    public store: CommonDataService,
    private orderService: OrderService
  ) {}

  ngOnInit() {
    this.qtys = [];
    for (let i = 1; i <= 20; i++) {
      this.qtys.push(i);
    }
    this.createForm();
    this.getCart();
    var self = this;
    this.standardShippingFee = environment.shippingFee;
    this.expressShippingFee = environment.expressFee;
  }

  removeItem(item, i) {
    if (!confirm("Delete?")) return;
    this.orderService.removeOrderItem(item.orderItemId).subscribe((data) => {
      console.log("removed", data);

      if (data && data.results && data.results.cartInfo) {
        this.store.setBadge("cart", data.results.cartInfo.numberOfItems);
      }
      // arrayUtils.remove(this.cart.orderItems, (x) => { return x.orderItemId == item.orderItemId });
      // var orderItems = this.formGroupCart.controls['orderItems'] as FormArray;
      // orderItems.removeAt(i);
      this.loadingStatus.loading();
      this.loaded = false;
      this.getCart();
    });
  }

  stepChanged(e) {
    console.log(e);
    this.scrollToSectionHook();
    if (e.previouslySelectedIndex == 0) {
      // save order items
      this.saveCartItems();
    } else if (e.previouslySelectedIndex == 1 && e.selectedIndex == 2) {
      // save shipping/billing info
      this.saveCartShippingInfo();
    }
  }

  private scrollToSectionHook() {
    const element = (document as any).querySelector("h1");
    if (element) {
      setTimeout(() => {
        element.scrollIntoView({
          block: "start",
          inline: "nearest",
          behavior: "smooth",
        });
      }, 250);
    }
  }

  formGroupCart: FormGroup;
  formGroupShipping: FormGroup;
  formGroupPay: FormGroup;

  hasError(formGroup: FormGroup, fieldName: string) {
    return ngFormUtils.hasError(formGroup, fieldName);
  }

  createForm() {
    this.formGroupCart = this.fb.group({
      orderItems: this.fb.array([]),
      shippingMethod: ["", Validators.required],
    });
    this.formGroupShipping = addressForm.createFormGroup(
      this.fb,
      this.authService.isLoggedIn
    );
    this.formGroupPay = this.fb.group({
      cardName: "",
      cardNumber: "",
      cardExpMM: "",
      cardExpYY: "",
      CVV: "",
    });
  }

  setForm(cart) {
    var orderItems = this.formGroupCart.controls["orderItems"] as FormArray;
    //console.log(cart, orderItems)
    orderItems.controls.splice(0);

    if (cart && cart.orderItems) {
      cart.orderItems.forEach((x) =>
        orderItems.push(
          this.fb.group({
            orderItemId: [x.orderItemId],
            qty: [x.quantity, Validators.required],
          })
        )
      );
    }

    this.formGroupCart.controls["shippingMethod"].reset(cart.shippingMethod);

    this.formGroupCart.valueChanges.subscribe((val) => {
      this.reCalculate(val);
      let useSameAddress =
        this.shippingMethod != "Pickup" && cart.useSameAddress;
      this.formGroupShipping.controls["useSameAddress"].setValue(
        useSameAddress
      );
      this.formGroupShipping.controls["useShippingAddress"].setValue(
        this.shippingMethod != "Pickup"
      );
    });

    let useSameAddress = this.shippingMethod != "Pickup" && cart.useSameAddress;
    this.formGroupShipping.controls["useSameAddress"].setValue(useSameAddress);
    this.formGroupShipping.controls["useShippingAddress"].setValue(
      this.shippingMethod != "Pickup"
    );
    this.formGroupShipping.controls["specialRequest"].setValue(
      cart.specialRequest
    );
    addressForm.resetAddress(
      this.formGroupShipping.controls["shippingInfo"],
      cart.shippingAddress
    );
    addressForm.resetAddress(
      this.formGroupShipping.controls["billingInfo"],
      cart.billingAddress
    );

    let saveAsFavouriteShippingAddress =
      this.shippingMethod != "Pickup" && cart.saveAsFavouriteShippingAddress;
    this.formGroupShipping.controls["saveAsFavouriteShippingAddress"].setValue(
      saveAsFavouriteShippingAddress
    );

    let saveAsFavouriteBillingAddress =
      this.shippingMethod != "Pickup" && cart.saveAsFavouriteBillingAddress;
    this.formGroupShipping.controls["saveAsFavouriteBillingAddress"].setValue(
      saveAsFavouriteBillingAddress
    );

    this.formGroupShipping.controls["gender"].setValue(cart.ext.gender);
    this.formGroupShipping.controls["dob"].setValue(cart.ext.dob);
    this.formGroupShipping.controls["productCategories"].setValue(
      cart.ext.productCategories
    );
    this.formGroupShipping.controls["skinType"].setValue(cart.ext.skinType);
  }

  hasShippingError() {
    return ngFormUtils.hasError(
      this.formGroupShipping.controls["shippingInfo"] as FormGroup,
      "shippingInfoCompleted"
    );
  }

  hasShippingMethodError() {
    return ngFormUtils.hasError(
      this.formGroupCart as FormGroup,
      "shippingMethod"
    );
  }

  continueShopping() {
    this.router.navigate(["/products"]);
  }

  checkAddress() {
    ngFormUtils.markFormGroupTouched(this.formGroupShipping);
    console.log(this.formGroupShipping);
  }

  get shippingMethod() {
    return this.formGroupCart.controls["shippingMethod"].value;
  }

  selectShippingAddress() {
    const dialogRef = this.dialog.open(SelectAddressPopupComponent, {
      width: utils.getPopupWidth(),
      data: this.cart,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        addressForm.resetAddress(
          this.formGroupShipping.controls["shippingInfo"],
          result
        );
      }
    });
  }

  selectBillingAddress() {
    const dialogRef = this.dialog.open(SelectAddressPopupComponent, {
      width: utils.getPopupWidth(),
      data: this.cart,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        addressForm.resetAddress(
          this.formGroupShipping.controls["billingInfo"],
          result
        );
      }
    });
  }

  reCalculate(formValue) {
    let total = 0;
    if (
      formValue &&
      formValue.orderItems &&
      this.cart &&
      this.cart.orderItems
    ) {
      this.cart.orderItems.forEach((orderItem) => {
        let oi = arrayUtils.find(
          formValue.orderItems,
          (x) => x.orderItemId == orderItem.orderItemId
        );
        if (oi) {
          total += (orderItem.unitPrice + orderItem.addedPrice) * oi.qty;
        }
      });
    }
    this.itemTotalAmount = total;
    this.isFreeShipping = total >= environment.freeShipping;

    if (this.shippingMethod == "Express") {
      this.shippingFee = environment.expressFee;
    } else if (this.shippingMethod == "Pickup") {
      this.shippingFee = 0;
    } else {
      this.shippingFee = this.isFreeShipping ? 0 : environment.shippingFee;
    }
  }

  getCart(): void {
    this.orderService.getCart().subscribe(
      (cart) => {
        console.log(cart);
        if (cart) {
          this.setForm(cart);
          this.cart = cart;
          this.itemTotalAmount = cart.itemAmount;
          this.shippingFee = cart.shippingAmount;
        }
        this.loadingStatus.loaded();
        this.loaded = true;
        this.reCalculate(this.formGroupCart.value);
      },
      (err) => {
        this.loadingStatus.showErrorMessage(
          "Fail to load cart information",
          err.message,
          err
        );
      }
    );
  }

  submitCartItems() {
    console.log("submitCartItems");
    ngFormUtils.markFormGroupTouched(this.formGroupCart);
    if (!this.formGroupCart.valid) return;
  }

  saveCartItems() {
    this.orderService
      .saveCartItems(this.formGroupCart.value)
      .subscribe((data) => {
        console.log("saved", data);
      });
  }

  saveCartShippingInfo() {
    var shipping = this.formGroupShipping as FormGroup;
    //console.log(shipping.value);
    this.orderService.saveCartShippingInfo(shipping.value).subscribe((data) => {
      console.log("saved", data);
    });
  }

  //#region pay
  payNow() {
    var clientApiKey = environment.clientApiKey;
    var payGroup = this.formGroupPay as FormGroup;
    if (payGroup.invalid) return;
    this.errors = [];
    // console.log(payGroup.value)
    var payment = {
      itemTotalAmount: this.itemTotalAmount,
      cardName: payGroup.value.cardName,
      cardNumber: eCrypt.encryptValue(
        payGroup.value.cardNumber.toString(),
        clientApiKey
      ),
      cardExpMM: payGroup.value.cardExpMM,
      cardExpYY: payGroup.value.cardExpYY,
      CVV: eCrypt.encryptValue(payGroup.value.CVV, clientApiKey),
    };
    // console.log(payGroup.value, payment);
    this.orderService.pay(payment).subscribe((data) => {
      if (data.errors.length > 0) {
        data.errors.forEach((err) => {
          this.errors.push(err.errorMessage);
        });
      } else {
        this.store.setBadge("cart", 0);
        console.log("paid", data);
        this.cookieService.delete("Ecm6.CartId." + environment.siteCode);
        this.router.navigate(["/order-thankyou"], {
          queryParams: { o: data.results.OrderIdToken },
        });
      }
    });
  }
  //#endregion
}
