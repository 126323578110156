import { Component, OnInit, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

import { FormBuilder, FormGroup, FormArray, Validators } from "@angular/forms";
import { CommonErrorStateMatcher } from "@app/core/error-status-matcher";
import { MemberService } from "@app/member/services/member.service";

@Component({
  selector: "app-address-edit-popup",
  templateUrl: "./address-edit-popup.component.html",
  styles: [],
})
export class AddressEditPopupComponent implements OnInit {
  formGroup: FormGroup;
  matcher = new CommonErrorStateMatcher();
  errorMessage: string;

  constructor(
    private fb: FormBuilder,
    private profileService: MemberService,
    public dialogRef: MatDialogRef<AddressEditPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.createForm();
    this.loadData(this.data);
  }

  createForm() {
    console.log(this.data);
    this.formGroup = this.fb.group({
      firstName: ["", Validators.required],
      surname: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", Validators.required],
      addressLine: ["", Validators.required],
      suburb: ["", Validators.required],
      state: ["", Validators.required],
      postcode: ["", Validators.required],
      country: "",
    });
  }

  loadData(model) {
    this.formGroup.reset(model);
  }

  isEdit() {
    return this.data.userProfileAddressId;
  }

  onSubmit() {
    this.errorMessage = "";
    console.log(this.formGroup.value);
    if (this.formGroup.invalid) return;

    let model = Object.assign(
      { userProfileAddressId: this.data.userProfileAddressId },
      this.formGroup.value
    );

    this.profileService.saveAddress(model).subscribe((data) => {
      console.log(data);
      if (data.errors && data.errors.length > 0) {
        this.errorMessage = data.errors[0].errorMessage;
      } else {
        this.dialogRef.close(true);
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  delete() {
    if (!confirm("Delete this address?")) return;

    this.errorMessage = "";

    this.profileService
      .removeAddress({ userProfileAddressId: this.data.userProfileAddressId })
      .subscribe((data) => {
        console.log(data);
        if (data.errors && data.errors.length > 0) {
          this.errorMessage = data.errors[0].errorMessage;
        } else {
          this.dialogRef.close(true);
        }
      });
  }
}
