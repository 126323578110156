import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  ValidatorFn,
  AbstractControl,
} from "@angular/forms";

export function createFormGroup(fb: FormBuilder, loggedIn: boolean) {
  var requiredDecision = !loggedIn ? [Validators.required] : [];
  var formGroup = fb.group({
    shippingInfo: fb.group({
      firstName: ["", Validators.required],
      surname: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      phone: ["", Validators.required],
      addressLine: ["", Validators.required],
      suburb: ["", Validators.required],
      state: ["", Validators.required],
      postcode: ["", Validators.required],
      country: "",
    }),
    saveAsFavouriteShippingAddress: [true],
    saveAsFavouriteBillingAddress: [true],
    useShippingAddress: [true],
    useSameAddress: [true],
    billingInfo: fb.group({
      firstName: "",
      surname: "",
      email: "",
      phone: "",
      addressLine: "",
      suburb: "",
      state: "",
      postcode: "",
      country: "",
    }),
    specialRequest: "",
    gender: [null, requiredDecision],
    dob: ["", requiredDecision],
    productCategories: [null, requiredDecision],
    skinType: [null, requiredDecision],
  });

  // conditional validation
  formGroup
    .get("useShippingAddress")
    .valueChanges.subscribe((useShippingAddress: boolean) => {
      resetValidator(formGroup.get("shippingInfo"), useShippingAddress);
    });
  formGroup
    .get("useSameAddress")
    .valueChanges.subscribe((useSameAddress: boolean) => {
      resetValidator(formGroup.get("billingInfo"), !useSameAddress);
    });

  return formGroup;
}

export function resetValidator(formGroup, validationRequired) {
  if (validationRequired) {
    formGroup.get("firstName").setValidators([Validators.required]);
    formGroup.get("surname").setValidators([Validators.required]);
    formGroup
      .get("email")
      .setValidators([Validators.required, Validators.email]);
    formGroup.get("phone").setValidators([Validators.required]);
    formGroup.get("addressLine").setValidators([Validators.required]);
    formGroup.get("suburb").setValidators([Validators.required]);
    formGroup.get("state").setValidators([Validators.required]);
    formGroup.get("postcode").setValidators([Validators.required]);
  } else {
    formGroup.get("firstName").setValidators(null);
    formGroup.get("surname").setValidators(null);
    formGroup.get("email").setValidators(null);
    formGroup.get("phone").setValidators(null);
    formGroup.get("addressLine").setValidators(null);
    formGroup.get("suburb").setValidators(null);
    formGroup.get("state").setValidators(null);
    formGroup.get("postcode").setValidators(null);
  }
  formGroup.get("firstName").updateValueAndValidity();
  formGroup.get("surname").updateValueAndValidity();
  formGroup.get("email").updateValueAndValidity();
  formGroup.get("phone").updateValueAndValidity();
  formGroup.get("addressLine").updateValueAndValidity();
  formGroup.get("suburb").updateValueAndValidity();
  formGroup.get("state").updateValueAndValidity();
  formGroup.get("postcode").updateValueAndValidity();
}

export function resetAddress(infoGroup, addr) {
  infoGroup.reset(Object.assign(infoGroup.value, addr));
}
