import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  private url = environment.apiEndpoint + '/api/CategoryAdmin';
  constructor(private http: HttpClient) { }
  getCategories(): Observable<any[]> {
    return this.http.get<any[]>(this.url + "/GetList");
  }
  getCategory(id): Observable<any> {
    return this.http.get<any>(this.url + "/Get/" + id);
  }

  saveCategory(catgory: any): Observable<any>  {
    return this.http.post(this.url + "/SaveCategory", catgory)
      .pipe();
  }
  saveProductSort(catgory: any): Observable<any>  {
    return this.http.post(this.url + "/SaveProductSort", catgory);
  }
}
