import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromStore from "@app/store";
import { SetPage, SetMenu } from "@app/store/cms.actions";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-based-layout",
  templateUrl: "./app-based-layout.component.html",
  styles: [],
})
export class AppBasedLayoutComponent implements OnInit {
  constructor(
    // private store: Store<fromStore.State>,
    //private location: Location,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // this.loadPage();
  }

  // loadPage(): void {
  //   //console.log((<any>window)._pageData)
  //   if ((<any>window)._pageData) {
  //     this.renderPage((<any>window)._pageData);
  //     //setTimeout(()=> {this.rendered = true; this.cd.detectChanges();}, 500)
  //   } else {
  //     // this.pageService.getPublishedPage(path)
  //     //   .subscribe(pageData => {
  //     //     this.renderPage(pageData);
  //     //   },
  //     //     err => {
  //     //       this.loadingStatus.showErrorMessage('Fail to load page information', err.message, err);
  //     //     });
  //   }
  // }
  // renderPage(pageData) {
  //   if (pageData && pageData.menu) {
  //     this.store.dispatch(new SetMenu(pageData.menu));
  //   }
  //   // remove server injected data
  //   (<any>window)._pageData = null;
  // }

  cssClass() {
    return this.route.snapshot["_routerState"].url == "/"
      ? "subpage homepage"
      : "subpage";
  }
}
