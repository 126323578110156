import { FormGroup, FormControl, FormArray } from "@angular/forms";

export function hasError(formGroup: FormGroup, fieldName: string) {
  var control = formGroup.get(fieldName);
  if (control) {
    if (control.invalid && (control.dirty || control.touched)) return true;
  }
  return false;
}

// get all values of the formGroup, loop over them
// then mark each field as touched
export function markFormGroupTouched(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      control.markAsTouched({ onlySelf: true });
      markFormGroupTouched(control);
    }
  });
  formGroup.markAsTouched({ onlySelf: true });
}

export function updateValueAndValidity(formGroup: FormGroup) {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      control.updateValueAndValidity({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      control.updateValueAndValidity({ onlySelf: true });
      updateValueAndValidity(control);
    }
  });
  formGroup.updateValueAndValidity({ onlySelf: true });
}

export function hasError2(control, errorType = null) {
  //var control = formGroup.get(fieldName);
  if (control) {
    if (control.invalid && (control.dirty || control.touched)) {
      // console.log(control.errors, errorType, control.errors[errorType])
      if (!errorType) return true;
      if (control.errors && control.errors[errorType]) {
        return true;
      }
    }
  }
  return false;
}

// getError(formGroup: FormGroup, fieldName: string) {
//   var control = formGroup.get(fieldName);
//   if (control) {
//     // console.log(control);
//     if (control.invalid && (control.dirty || control.touched))
//       return control.errors;
//   }
//   return [];
// }

export function clearFormArray(formArray: FormArray) {
  formArray.controls.splice(0);
  // while (formArray.length !== 0) {
  //   formArray.removeAt(0)
  // }
}

export function truncateFormArray(formArray: FormArray, size: number) {
  formArray.controls.splice(size);
  // while (formArray.length !== 0) {
  //   formArray.removeAt(0)
  // }
}
