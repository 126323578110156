import { Action } from '@ngrx/store';
import { CmsActionTypes, CmsActions } from './cms.actions';

export interface State {
  page: any,
  menu: any,
  masqueradingUser: any
}

export const initialState: State = {
  page: null,
  menu: null,
  masqueradingUser: null
};

export function reducer(state = initialState, action: CmsActions): State {
  console.log(state, action)
  switch (action.type) {
    case CmsActionTypes.SetPage:
      return { ...state, page: action.payload };

    case CmsActionTypes.SetMenu:
      return { ...state, menu: action.payload };

    case CmsActionTypes.SetMasqueradingUser:
      return { ...state, masqueradingUser: action.payload };

    default:
      return state;
  }
}

// export const selectPage = (state: State) => state.page;
// export const selectMenu = (state: State) => state.menu;
