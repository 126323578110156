import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styles: []
})
export class ErrorMessageComponent implements OnInit {
  @Input() errors: string[];
  constructor() { }

  ngOnInit() {
  }
}
